import { action, observable } from 'mobx';
import {configure,makeObservable} from "mobx";
//import AsyncStorage from '@react-native-async-storage/async-storage';
import AsyncStorage from '@react-native-community/async-storage';

configure({ enforceActions: "never" })

class UserStore {
  @observable sessiontoken = '';
  @observable unreadmessagecount =0;
  @observable userloggedin = false;
  useremail = '';
  @observable firstname = '';
  @observable lastname = '';
  @observable userphotothumbnailurl = '';
  @observable fontsize = 1;
  @observable screenupdate = 1;

  constructor() {
    // Just call it here
    makeObservable(this);
    //https://mobx.js.org/observable-state.html
    // makeAutoObservable(this);
  }
  @action updatescreen(){this.screenupdate=this.screenupdate+1;}

  @action checktoken(){
    let tokenparam='None';
    return;
    if (global.token) {tokenparam='Token '+global.token} 
   
   
      
      fetch(global.apiurl+'/clinicians/checktoken/', {
          method: "GET", 
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': tokenparam,
          },
        })
.then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson);
            console.log(responseJson.result);
           
            if (responseJson.result=="false") {
          console.log("checktoken  NOK");

       //   this.logout();
        }else{

          console.log("checktoken  OK");

        }
       
          })
        
        }





//https://stackoverflow.com/questions/50196003/how-to-initialize-mobx-store-with-async-data-asyncstorage-in-react-native
 syncWithAsyncStorage() {

console.log("syncWithAsyncStorage");




  AsyncStorage.setItem('sessiontoken',this.sessiontoken);
  


    var values = [['sessiontoken', (this.sessiontoken)], 
    ['userloggedin',(this.userloggedin)],

    ['userphotothumbnailurl', (this.userphotothumbnailurl)],
  ];
 
    AsyncStorage.multiSet(values); 
  }
 
  @action async initializeFromAsyncStorage() {
    console.log("initializeFromAsyncStorage");


    this.sessiontoken=await AsyncStorage.getItem('sessiontoken')
    this.userloggedin=await AsyncStorage.getItem('userloggedin')
    this.userphotothumbnailurl=await AsyncStorage.getItem('userphotothumbnailurl')
console.log("session token");
console.log(this.sessiontoken);
console.log(this.userloggedin);
console.log(this.userphotothumbnailurl);


  }

   

  @action async setUserPhotoThumbnailURL(userphotothumbnailurl) {
   console.log("setUserPhotoThumbnailURL:"+userphotothumbnailurl);
    this.userphotothumbnailurl=userphotothumbnailurl;
    await AsyncStorage.setItem('userphotothumbnailurl',userphotothumbnailurl);

   }

 


  @action setUnreadmessagecount(unreadmessagecount:Number) {
  
   this.unreadmessagecount=unreadmessagecount;

  }

  @action setToken(newSessiontoken: string) {
    console.log("setToken");
    AsyncStorage.setItem('sessiontoken',newSessiontoken);
  }

  
    @action async setLoggedin(newSessiontoken: string,userphotothumbnailurl:string) {
    console.log("setLoggedin");
   
      await AsyncStorage.setItem('sessiontoken',newSessiontoken);
      await AsyncStorage.setItem('userphotothumbnailurl',userphotothumbnailurl);
      await AsyncStorage.setItem('userloggedin',true)

      this.sessiontoken = newSessiontoken;


      this.userphotothumbnailurl=userphotothumbnailurl;

      this.userloggedin=true;
    
      global.loggedin=true;
      global.token=newSessiontoken;
      
    
      console.log("loggedin:"+newSessiontoken);
      console.log("loggedin:"+userphotothumbnailurl);
   
    }

 @action  async logout() {
   console.log("logout!!")
            
   await AsyncStorage.removeItem('sessiontoken',"")
   await AsyncStorage.removeItem('userphotothumbnailurl',"")
   await AsyncStorage.removeItem('userloggedin')

global.token="";
global.loggedin=false;

this.sessiontoken="";
this.userphotothumbnailurl="";
this.userloggedin=false;



     // this.syncWithAsyncStorage();

      console.log("loggedout");

    }
  



  @action checkmessages(){
      if (global.loggedin) {tokenparam='Token '+global.token} else {tokenparam='None';}
  
  
         fetch(global.apiurl+'/ilan/usermsgs/',{  method: "GET",
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': tokenparam,
        },
      }).then((response) => response.json())
        .then((responseJson) => {
      

      
          //Alert.alert("asd");
          userStore.setUnreadmessagecount(responseJson.unreadmessagecount);
            })
        .catch((error) => {
          console.error(error);
        });
      
      


  }
}

/*
decorate(UserStore, {
  sessiontoken: observable,
  userloggedin: observable,
  logout: action,
});*/

  const userStore = new UserStore();
  userStore.initializeFromAsyncStorage()
  global.apiurl='http://postop.ai:5000';
  global.imagepathurl='http://postop.ai:5000/';

  global.countrycode='90'; 
  


  //localtest
  if (false){
    global.apiurl='http://localhost:5000';
    global.imagepathurl='http://localhost:5000/';
    global.countrycode='90'; 


  } 
  if (false){
    global.apiurl='http://mypostop.ai:5000';
    global.imagepathurl='http://mypostop.ai:5000/';
    global.countrycode='90'; 


  }
  //setInterval(function(){ userStore.checkmessages(); }, 3500);
  

  export default userStore;
