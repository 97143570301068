import * as React from 'react';
import {Alert,TextInput,Dimensions, View,Text ,StyleSheet,Image,TouchableOpacity} from 'react-native';

import { OrangeButton } from '../../components/OrangeButton';
import validate from '../validate_wrapper'
import { inject, observer } from 'mobx-react';
import { PasswordStrengthMeter } from '../../components/PasswordStrengthMeter';

const RegisterSetPasswordScreen=inject("userStore")(({userStore,route,navigation})=> {

    const [newpassword, setNewpassword] = React.useState('');
    const [newpassword2, setNewpassword2] = React.useState('');
    const [secure, setSecure] = React.useState(true);
    const [secure2, setSecure2] = React.useState(true);
  const {email,temppassword} = route.params;
  const [passwordstrength, setPasswordstrength] = React.useState(0);

    const dimensions = Dimensions.get('window');
   

    function validateForm(){
      
      
        console.log("validateForm::firstpassword");
        
        
        
      const passwordError = validate('password', newpassword);
      let password2Error = null;

      let passwordStrengthError = null;

      if ((newpassword.length>4) && (passwordstrength<3)){passwordStrengthError="Password is weak."};
   

     
      if (newpassword!=newpassword2){"Password's don't match."};

        if (!(passwordError||password2Error )) {
          console.log("no error setting password");
      setfirstpassword();
        } else {
          alert("\n"+(passwordStrengthError?"\n"+passwordStrengthError+"\n":"")+(passwordError?"\n"+passwordError+"\n":"")+(password2Error?"\n"+password2Error+"\n":"")+"\n");
          
        }
      }


      function setfirstpassword() {
        console.log("setting password");
    
          fetch(global.apiurl+'/clinicians/setfirstpassword', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: email,
              temppassword: temppassword,
              newpassword: newpassword
            })
          })
            .then(res => {
              if (res.status === 422) {
    
                throw new Error('Request failed.');
              }
              if (res.status !== 200 && res.status !== 201) {
                console.log('Error!');
                throw new Error('Could not authenticate you!');
              }
              return res.json();
            })
            .then(resData => {
              console.log(resData);
             if (resData.result){
               console.log("DONE");
               console.log(resData);
        userStore.setToken(resData.token);
      
        navigation.replace('RegisterUpdateDetailsScreen');
  //      global.token=resData.token;

    
    //    global.tempreg={firstname:resData.firstname,lastname:resData.lastname,token:resData.token};
    
      
    
    
    
              } else {
      
             alert("Unable change password\n\nPlease check your password");
            }
             
          
            })
            .catch(err => {
              console.log(err);
             
            });
        }
    


  return (
    <View style={styles.containersplit}>
      
 <View style={{width:dimensions.width/2-350-100,flexShrink:0.5}}/>

<View style={{minWidth:350,height:"100%",alignItems:"flex-start",backgroundColor:"#ffffff"}}>

 
    <View style={{width:350,flexShrink:0.5,backgroundColor:"#ffffff"}}>
    <Image
source={require('../../assets/images/logopostop.png')}
style={{width:178,height:61,marginTop:93}}     />
    <Text style={styles.title1}>Account created!<br/>
Now, set a new password <br/>
for your account.</Text>


  <Text style={styles.title5}>
  Set new password  </Text>
  <View  style={styles.shadowinput} >
    <TextInput returnKeyType='done'
        style={styles.passwordinput} keyboardType="email-address"
        secureTextEntry={secure} 
        onChangeText={(text) => setNewpassword (text.trim())}
        value={newpassword}
       
      />

<TouchableOpacity onPress={() => setSecure(!secure)}>
      <Image  
       source={require('../../assets/images/passwordshow.png')}
       style={styles.passwordshow}     />
       </TouchableOpacity>
      </View>

      
      <PasswordStrengthMeter style={{marginTop:11}} pw={newpassword} setLabel={x=>console.log(x)} setStrenght={x=>{setPasswordstrength(x);console.log("pw strength:"+x);}}  />

  <Text style={styles.title5}>
  Repeat new password
  </Text>
  <View  style={styles.shadowinput} >
    <TextInput returnKeyType='done'
        style={styles.passwordinput} keyboardType="email-address"
        secureTextEntry={secure2} 
        onChangeText={(text) => setNewpassword2 (text.trim())}
        value={newpassword2}
       
      />

<TouchableOpacity onPress={() => setSecure2(!secure2)}>
      <Image  
       source={require('../../assets/images/passwordshow.png')}
       style={styles.passwordshow}     />
       </TouchableOpacity>
      </View>

<OrangeButton onPress={() => validateForm()} title="Create Account"  on style={{marginBottom:118,marginTop:93}} />

      </View>

   
</View>
<View style={{width:197,flexShrink:0.5}}>

</View>
<Image
source={require('../../assets/images/bglogin.jpg')}
style={{width:"50%",height:"100%",resizeMode: "cover",flexShrink:1,
}}     />



    </View>
  );
});

export default RegisterSetPasswordScreen;
const styles = StyleSheet.create({
  

  containersplit: {
    flex: 1,
    flexDirection:"row",
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  
  },
  phoneinput:{
    flex:1,outlineWidth:0,
    color:"#1F2E99",fontFamily:"Lato",fontSize:15,
    marginRight:10,
    marginLeft:20,
  },
  shadowinput:{
        
    marginTop:15,
    height:48,
   flexDirection: 'row',borderRadius:15,
    shadowColor: "#818181",
    backgroundColor:"#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    
    elevation: 5,

  },
  title1: {
    fontSize: 32,
    fontFamily: 'LatoBold',
color:"#1F2E99",
marginTop:32,
width:386

  },
  title2: {
      marginTop:18,
    fontSize: 15,
    fontFamily: 'Lato',
color:"#000000",

  }, 
   title3: {
    fontSize: 11,
    lineHeight: 14,
    color:"#000000",
    fontFamily: 'Lato',

  },
  title4:{
    fontSize: 11,
    lineHeight: 14,
    color:"#0009EC",
    fontFamily: 'Lato',

  },
  title5:{
      marginTop:32,
    fontSize: 13,
    color:"#798599",
    fontFamily: 'Lato',

  }, title6:{
      marginTop:8,
    fontSize: 11,
    color:"#798599",
    fontFamily: 'Lato',

  },
  passwordshow: {
    width: 24,
    height:15,
    position:"relative",
    top:20,
    right:15,
  },
 
  passwordinput:{
    width:"100%",
    flex:1,outlineWidth:0,
        color:"#1F2E99",fontFamily:"Lato",fontSize:15,
        marginRight:22,
        marginLeft:20,
    
      },
  
});
