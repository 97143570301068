import * as React from 'react';
import { Text,View,StyleSheet ,Dimensions,Image,TouchableOpacity} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

//export function OrangeButton(props) {
    export const Comment = (props) => {
        const insets = useSafeAreaInsets();

        const dimensions = Dimensions.get('window');
const bottomdistance=dimensions.height/25+insets.bottom;
    return   <View style={{flexDirection:"row",width:"100%",marginBottom:50,backgroundColor:"#ffffff",height:94,alignItems:"center",borderRadius:6,}}>
    <Image style={{width:32,height:32,borderRadius:16,marginRight:17,marginLeft:19}} 
    source={{uri:global.imagepathurl+encodeURI(props?.photourl)}}

 
         />

    <View  >

    <View style={{flexDirection:"row",alignItems:"center",marginTop:8}}>
  
        <Text style={[styles.title4]}>{props.fullname}</Text>
        <Text style={[styles.title5]}>{props.date}</Text>
  
    </View>
    <View style={{flexDirection:"row",alignItems:"center",marginTop:8}}>
    
      <Text style={styles.title6}>{props.msg}</Text></View>
    
    </View>
    </View>



    ;
 // return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}

const styles = StyleSheet.create({
    title4:{
        color:"#000000",
        fontFamily:"LatoBold",
        fontSize:13
    } , 
    title5:{
        color:"#646464",
        fontFamily:"Lato",
        fontSize:13,
        marginLeft:11
    } , 
    title6:{
        color:"#1F2E99",
        fontFamily:"Lato",
        fontSize:16,
        width:394
        
    } ,
  });
  