import {Animated,Dimensions, Modal,TouchableHighlight,TouchableOpacity,View,Text ,StyleSheet,TextInput,Image,FlatList} from 'react-native';
import React, { useState, useEffect } from 'react';

import { InvitePatient } from '../components/InvitePatient';
import { BlueButton } from '../components/BlueButton';
import { Hairline } from '../components/Hairline';
import { MainFrame } from '../components/MainFrame';

export default function PatientListScreen(props) {
  const [searchtext, setSearchtext] = React.useState("");
  const [modalInviteVisible, setModalInviteVisible] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const dimensions = Dimensions.get('window');


  const opacity = new Animated.Value(1);

 
 function fadeIn () {


 setVisible(true);


  };
  
    function fadeOut () {
      Animated.timing(opacity, {
          toValue: 0,
          duration: 175
        }).start(()=>setVisible(false));
       
    };

  return (

    <View style={styles.container}>
    
     
{!!visible&&  <Animated.View  style={
            {
              position:"absolute",top:0,zIndex:100,width:"100%",
                opacity:opacity,
                height:Math.max(dimensions.height,826)
             
            }
          }
        ><InvitePatient  visible={visible} removeView={fadeOut}></InvitePatient></Animated.View>}


      <MainFrame patientlist style={{}}>
<View style={{alignItems:"flex-start",width:1000}}>
      
<Text style={[styles.title1]}  >List of patients</Text>
<Text style={[styles.title2]} >16 Patients</Text>
<BlueButton onPress={()=>fadeIn()} invitenewuser title="Invite new patient" style={{position:"absolute",right:0,top:32,width:180}} />

<View style={{flexDirection:"row",alignItems:"center",marginTop:24}}>
<View style={[styles.shadowinput, { width: 267 }]} >
<TextInput
  style={[styles.postopinput, { textAlign: "left", width: 220 }]} keyboardType="email-address"
  maxLength={50}
  numberOfLines={1}
  onChangeText={text => setSearchtext(text )}
  value={searchtext}   /> 
<TouchableOpacity  style={{ position:"absolute",right:10,top:13 }}>
  <Image
    source={require('../assets/images/searchicon.png')}
    style={styles.searchicon} />
</TouchableOpacity>
</View>
<TouchableOpacity style={{marginLeft:40,marginTop:8}}>
<View style={{flexDirection:"row",alignItems:"center",height:44}}>
<Image
    source={require('../assets/images/filtericon.png')}
    style={styles.filtericon} />

    <Text style={[styles.title3,{    marginLeft:6.67}]}>Filter by</Text>
</View>
</TouchableOpacity>

</View>

<View style={{flexDirection:"row",width:"100%",justifyContent:"space-between",marginTop:24.5}}>

<TouchableOpacity style={{flex:0.30}}><Text style={[styles.title3]}>Name</Text></TouchableOpacity>
  <TouchableOpacity  style={{flex:0.30}}><Text style={[styles.title3]}>Role</Text></TouchableOpacity>
  <TouchableOpacity  style={{flex:0.40}}> <Text style={[styles.title3]}>Clinical teams</Text></TouchableOpacity>
  </View>
  <Hairline marginTop={15.5}/>

<FlatList  
 style={{width:"100%"}}
  data={[{ title: 'Title Text', key: 'item1' },{ title: 'Title Text', key: 'item2' },{ title: 'Title Text', key: 'item13' }]}
  renderItem={({ item, index, separators }) => (
    <TouchableOpacity onPress={()=>props.navigation.push('PatientOverviewScreen',{id:item.key})}

      key={item.key}
      >
    
    <View style={{flexDirection:"row",width:"100%",justifyContent:"space-between",marginTop:24.5,backgroundColor:"#ffffff",height:94,alignItems:"center",borderRadius:6,}}>
 <View style={{flex:0.10,}}>
    <Image style={{borderColor:"#0077FF",marginLeft:24,borderWidth:1,width:46,height:46,borderRadius:23,marginRight:18}} source={require('../assets/images/user.jpg')}     />
    </View>
  <View style={{flex:0.20}} ><Text style={[styles.title4]}>Sophia Rashford</Text>
  
  <View style={{flexDirection:"row",alignItems:"center",marginTop:8}}>
  <Image
    source={require('../assets/images/unreadmsgicon.png')}
    style={styles.unreadmsgicon} />
    <Text style={{color:"#FE5935",fontFamily:"Lato",fontSize:13,marginLeft:6}}>1 unread messages</Text></View>
  
  </View>
  <TouchableOpacity style={{flex:0.30}}><Text style={[styles.title4]}>Doctor</Text></TouchableOpacity>
  <View style={{flex:0.60}} ><Text style={[styles.title4]}>RD team, BG team </Text></View>
 
</View>


    </TouchableOpacity>
  )}
/>




</View>
      </MainFrame>
    


    </View>
  );
}

const styles = StyleSheet.create({


  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: '#F194FF',
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },


  searchicon:{
    marginTop:2,
    width:12.33,
    height:13.33

  }, 
    watchcloselycircleicon:{
    width:15,
    height:15

  }, 
  unreadmsgicon:{
    width:8,
    height:8

  },  
  flagicon:{
    width:15,
    height:18.333

  },  
  filtericon:{
    width:13.33,
    height:12

  },
  postopinput: {
    flex:1,outlineWidth:0,
    color:"#1F2E99",fontFamily:"Lato",fontSize:15,
    marginRight:10,
    marginLeft:20,
  },
  shadowinput:{
        
    marginTop:15,
    height:44,
   flexDirection: 'row',borderRadius:15,
    shadowColor: "#818181",
    backgroundColor:"#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    
    elevation: 5,

  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
   
    height:"100%"
  },
  title1: {
    fontSize: 24,
    fontFamily: 'LatoBold',
color:"#1F2E99",

marginTop:32
  },
  title2: {
    marginTop:12,

    fontSize: 15,
    fontFamily: 'Lato',
color:"#1F2E99",

  }, 
    title3: {
    fontSize: 13,
    fontFamily: 'Lato',
color:"#1F2E99",

  },
  titlecenter:{width:"100%",textAlign:"center"},
     title4: {
    fontSize: 15,
    fontFamily: 'Lato',
color:"#000000",

  }, 
   title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  
});
