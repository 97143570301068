const validation = {
    email: {
      presence: {
        message: '^Please enter an email address.'
      },
      email: {
        message: '^Please enter a valid email address.'
      }
    },


    gender: {
      presence: {
        message: '^Please select a gender.'
      },
      numericality: {
        greaterThan: 0,
        
        flags: "i",
        message: "^Please enter your gender"
      }
      
    },  biggerthanzero: {
      presence: {
        message: '^Please select a value.'
      },
      numericality: {
        greaterThan: 0,
        
        flags: "i",
        message: "^Please enter"
      }
      
    }, 
    
    dateofbirth: {
      presence: {
        message: '^Please enter date of birth.'
      },
      date:{
        message: '^Please enter a valid date of birth.'
      }
    },
    operationtype: {  
      presence: {
        message: '^Please select an operation type.'
      }
    },
    nhsnumber: {
      presence: {
        message: '^Please enter patient\'s NHS number.'
      }
    },
        clinicalteam: {
          numericality: {
            greaterThan: 0,
            
            flags: "i",
            message: "^Please select a clinical team."
          }

    
    },
    
    emailoptional: {
      
      email: {
        message: '^Please enter a valid email address.'
      }
    },
    firstname: {
      presence: {
        message: '^Please check your first name.'
      },
      length: {
        minimum: 1,
        message: '^Please check your first name.'
      },
    }, 

     genericname: {
      presence: {
        message: '^Please check your  name.'
      },
      length: {
        minimum: 1,
        message: '^Please check your  name.'
      },
    }, 


    
    lastname: {
      presence: {
        message: '^Please check your last name.'
      },
      length: {
        minimum: 1,
        message: '^Please check your last name.'
      }
    },
     caretakerfirstname: {
      presence: {
        message: '^Please check caretakers first name.'
      },
    }, caretakerlastname: {
      presence: {
        message: '^Please check caretakers last name.'
      },
    },
     caretakergsm: {
      presence: {
        message: '^Please enter caretakers phone number.'
      },
      length: {
        minimum: 6,
        message: '^Please enter caretakers phone number.'
      },
      format: {
        pattern: "[0-9]+",
        flags: "i",
        message: "^Please enter caretaker's phone number."
      }
    },
     caretakeremail: {
      email: {
        message: '^Please enter a valid caretakeremail\'s email address.'
      }
    },
    
    password: {
      presence: {
        message: '^Please enter a password.'
      },
      length: {
        minimum: 5,
        message: '^Password should have minimum 5 characters.'
      }
    },

    gsmcode: {
      presence: {
        message: '^Lütfen onay kodunu girin.'
      },
      length: {
        exact: 4,
        message: '^Onay kodunuz 4 karakterden oluşmalıdır.'
      }
    },

    username: {
      presence: {
        message: '^Lütfen kullanıcı adınızı girin.'
      },
      length: {
        minimum: 5,
        message: '^Kullanıcı adı en az 5 karakterden oluşmalıdır.'
      },
      format: {
        pattern: "[a-z0-9]+",
        flags: "i",
        message: "^Kullanıcı adınız sadece harf ve rakamlardan oluşmalıdır."
      }
    },

    first_name: {
      presence: {
        message: '^Lütfen adınızı girin.'
      },
      length: {
        minimum: 2,
        message: '^Adınız en az 2 karakterden oluşmalıdır.'
      }
    },

    last_name: {
      presence: {
        message: '^Lütfen soyadınızı girin.'
      },
      length: {
        minimum: 2,
        message: '^Soyadınız en az 2 karakterden oluşmalıdır.'
      }
    },


    height: {
      presence: {
        message: '^Please enter your height'
      },
      length: {
        minimum: 1,
        message: '^Please enter your height'
      },
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: 400,
        flags: "i",
        message: "^Please check your height"
      }
      
    },
    weight: {
      presence: {
        message: '^Please enter your weight'
      },
      length: {
        minimum: 1,
        message: '^Please enter your weight'
      },
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: 400,
        flags: "i",
        message: "^Please check your height"
      }
      
    },
    nhsnumber: {
      presence: {
        message: '^Please enter your NHS number'
      },
      length: {
        minimum: 3,
        message: '^Please enter your NHS number'
      },
      numericality: {
        greaterThan: 0,
        flags: "i",
        message: "^Please check your NHS number"
      }
      
    },
    gsm1: {
      presence: {
        message: '^Telefon operatör kodunu giriniz.'
      },
      length: {
        is: 3,
        message: '^Telefon alan kodunuz 3 rakamdan oluşmalıdır.'
      },
      format: {
        pattern: "[0-9]+",
        flags: "i",
        message: "^Telefon numarası sadece rakamlardan oluşmalıdır."
      }
    },
    invitationcode:{
      length: {
        is: 4,
        message: '^Invitation code should have 4 digits.'
      },
    },  
    resetpasswordcode:{
      length: {
        is: 6,
        message: '^Reset code is not valid.'
      },
    }, 
    gsm:{
      presence: {
        message: '^Please enter your phone number.'
      },
    },

    gsm2: {
      presence: {
        message: '^Please enter your phone.'
      },
      length: {
        minimum: 6,
        message: '^Please check your phone number.'
      },
      format: {
        pattern: "[0-9]+",
        flags: "i",
        message: "^Please check your phone number."
      }
    },
    title: {
      presence: {
        message: '^Lütfen başlık  girin.'
      },
      length: {
        minimum: 5,
        message: '^Başlık en az 5 karakterden oluşmalıdır.'
      }
    },
    description: {
      presence: {
        message: '^Lütfen açıklama  girin.'
      },
      length: {
        minimum: 5,
        message: '^Açıklama en az 15 karakterden oluşmalıdır.'
      }
    },
    budget: {
      format: {
        pattern: "[0-9]+",
        flags: "i",
        message: "^Bütçe sadece rakamlardan oluşmalıdır."
      }
    },  
    category: {
      presence: {
        message: '^Lütfen bir kategori seçin.'
      }
    },
  }
  
  export default validation