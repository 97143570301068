import React, { useState, useEffect } from 'react';
import { Text,View,StyleSheet ,Dimensions,Image,TouchableOpacity} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation, } from '@react-navigation/native';
import { A } from '@expo/html-elements';
import Hoverable from "../navigation/Hoverable";
import  {Avatar}  from '../components/Avatar'

//export function OrangeButton(props) {
    export const MainFrame = (props) => {

      const [modalNotificationsVisible, setModalNotificationsVisible] = useState(false);

      const navigation = useNavigation();

        const insets = useSafeAreaInsets();

        const dimensions = Dimensions.get('window');
const bottomdistance=dimensions.height/25+insets.bottom;
    return  <View style={{backgroundColor:"#FCF5EF",width:"100%",height:"100%",}}> 
    <View style={{zIndex:100,backgroundColor:"#FFFFFF",height:72,width:"100%",flexDirection:"row"}}>

<View   style={{flex:1}}>
<A href="/dashboard">
  

        <Image
source={require('../assets/images/logopostop.png')}
style={{width:97,height:32,marginTop:19,alignSelf:"flex-start",marginLeft:32,}}     />
  </A>
</View>
      <View style={{flexDirection:"row",flex:1,alignSelf:"center",justifyContent:"space-around"}} >


      <A href="/dashboard">
   {!props.dashboard&&<Image source={require('../assets/images/tabdashboard.png')} style={{width:132,height:25}}     />}
   {props.dashboard&&<Image source={require('../assets/images/tabdashboardselected.png')} style={{width:132,height:25}}     />}
   </A>

    <A href="/patients">
   {!props.patientlist&&<Image source={require('../assets/images/tabpatientlist.png')} style={{width:129,height:23}}     />}
   {props.patientlist&&<Image source={require('../assets/images/tabpatientlistselected.png')} style={{width:129,height:23}}     />}
    </A>
  
      </View>
      <View style={{flexDirection:"row",flex:1,justifyContent:"flex-end",alignSelf:"center"}} >


    <TouchableOpacity style={{width:30,marginRight:52}}
    onPress={() => {setModalNotificationsVisible(!modalNotificationsVisible)}}  >
 <Image source={require('../assets/images/notificationicon.png')} style={{width:16,height:20,marginRight:52,marginTop:7}}     />

 <View style={{
             position: 'absolute',
            right: -3,
            top:-2,
            backgroundColor: '#FE5935',
            borderRadius: 9,
            width: 18,
            height: 19,
            justifyContent: 'center',
            alignItems: 'center'
    ,zIndex:100
          }}><Text style={{fontSize:12,fontFamily:"LatoBold",color:"#FFFFFF"}}>4</Text></View>
   
    </TouchableOpacity>
<A href="/profile">
   
    <Avatar url={props?.userStore?.userphotothumbnailurl&&(global.imagepathurl+encodeURI(props?.userStore?.userphotothumbnailurl))} size={30} radius={15} firstname={props?.userStore?.firstname} lastname={props?.userStore?.lastname} style={{borderColor:"#0077FF",borderWidth:1,marginRight:32}}  />

   
    </A>
    <Hoverable
                    onHoverOut={setModalNotificationsVisible}
                    onHoverIn={console.log("out-image2")}

      > 
      {isHovered => (<View  style={[styles.whitebox,{position:"absolute",right:100,top:0,width:441}, !isHovered && { backgroundColor: "#ffffff" },!modalNotificationsVisible&&{top:-1000}]}>
       
           <Text style={{   fontSize: 18,
          fontFamily: 'LatoBold',
        color:"#1F2E99",marginTop:24,marginLeft:24,minHeight:300,zIndex:100}}>Notifications{isHovered}</Text>
        </View>)}

               </Hoverable>



      </View>

    </View>
    <View style={[{zIndex:0,width:"100%",alignItems:"center",overflow:"scroll"},props.style]}>
  {props.children}</View>
    </View>
    ;
 // return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}

const styles = StyleSheet.create({

  whitebox:{

    backgroundColor:"#ffffff",borderRadius:8,
    shadowColor: "#818181",
    shadowOffset: {
        width: 0,
        height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
},

  

       

   
    container: {
      backgroundColor:"#FCF5EF",
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
   
  
    
  });
  