import React, { useState, useEffect } from 'react';
import { Text,View,StyleSheet ,Dimensions,Image,TouchableOpacity,Modal} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation, } from '@react-navigation/native';
import { A } from '@expo/html-elements';
import Hoverable from "../navigation/Hoverable";
import { PatientMenu } from '../components/PatientMenu';
import { PatientHeader } from '../components/PatientHeader';
import { MainFrame} from '../components/MainFrame';

//export function OrangeButton(props) {
    export const MainFramePatient = (props) => { 



     return    <MainFrame patientlist  userStore={props.userStore} style={[{ alignItems: "flex-start", flexDirection: "row", width: "100%" }]}>
        <View style={{ width: 181, alignItems: "center", height: "100%" }}>
            <View style={{ height: 106, alignItems: "center", justifyContent: "center" }}>
                <A href="/patients">
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Image
                            source={require('../assets/images/backicon.png')}
                            style={{ width: 14.333, height: 10, marginRight: 11.5 }} />
                        <Text style={styles.title1}>Back to patient list </Text></View>
                </A>
            </View>
            <View style={{ width: 181, backgroundColor: "#ffffff", height: 1000, paddingTop: 11 }}>
                <PatientMenu  patientid={props?.patient?.id}  selected={props.selected} />
            </View>
            <View style={{ flexDirection: "row" }}>
                <View style={[styles.whitebox,]}></View>
            </View>
        </View>
        <View style={{ flexGrow: 1, alignSelf: "stretch", }}>
            <PatientHeader   patient={props?.patient}  />
            <View style={[{ paddingLeft: 63, paddingRight: 63, paddingTop: 20, alignSelf: "stretch", borderTopColor: "#1F2E99", borderTopWidth: 0.3, flexDirection: "row" },props.style]}>
            {props.children}
            </View>
        </View>
        <View></View>
    </MainFrame>
    ;
 // return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}

const styles = StyleSheet.create({
    whitebox: {
        backgroundColor: "#ffffff", borderRadius: 8,
        shadowColor: "#818181",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 5,
    },
    title1: {
        fontFamily: "Lato",
        fontSize: 13,
        color: "#1F2E99"
    }, 
  });
  