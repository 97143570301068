import React, { useState, useEffect } from 'react';
import {TextInput, FlatList,Text,View,StyleSheet ,Dimensions,Image,TouchableOpacity} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Comment } from './Comment';

//export function OrangeButton(props) {
  function getweek(dayno){
    return (1+Math.floor((dayno-1)/7))
  }

    export const WoundDetails = (props) => { 
let flatList;
const [isPinned, setIsPinned] = useState(null);
const [data, setData] = React.useState(null);
const [sendingData, setSendingData] = useState(false);

        const [ commenttext, setCommenttext] = React.useState("");
        const dimensions = Dimensions.get('window');



        function togglePin(){

          pinpatientmedia(data?.id,Math.abs(1-isPinned));
      
      }
      
      
      async function pinpatientmedia(ispinned) {
          let tokenparam = 'None';
          if (global.token) { tokenparam = 'Token ' + global.token }
          fetch(global.apiurl + '/clinicians/pinpatientmedia', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': tokenparam,
            },
            body: JSON.stringify({
                patientmediaid: data.id,
                ispinned: ispinned,
                
              })
          }).then((response) => response.json())
            .then(resData => {
              console.log(resData);
              if (resData.result) {
                  setIsPinned(ispinned);
              } else {
              }
      
            }).catch(err => {
              console.log(err);
           
            });
        }

        async function fetchData() {
          let tokenparam = 'None';
          
          if (global.token) { tokenparam = 'Token ' + global.token }
          fetch(global.apiurl + '/clinicians/getpatientmediadetails', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': tokenparam,
            },
            body: JSON.stringify({
              patientmediaid: props.pateientmediaid,
                
              })
          }).then((response) => response.json())
            .then(resData => {
              console.log(resData);
              if (resData.result) {
                setData(resData.patientmedia);
              } else {
              }
    
            }).catch(err => {
              console.log(err);
           
            });
        }
        

        async function sendComment() {
if (commenttext.length<3) return;
if(sendingData) return;
          setSendingData(true);
          console.log("sendComment");
          let tokenparam = 'None';
          
          if (global.token) { tokenparam = 'Token ' + global.token }
          fetch(global.apiurl + '/clinicians/addpatientmediacomment', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': tokenparam,
            },
            body: JSON.stringify({
              patiendmediaid: data?.id,
              message:commenttext
              })
          }).then((response) => response.json())
            .then(resData => {
              setSendingData(false);

              console.log(resData);
              if (resData.result) {
                fetchData();
                setCommenttext("");
              } else {
              }
    
            }).catch(err => {
              setSendingData(false);
              console.log(err);
           
            });
        }
    useEffect(() => {
        
        fetchData();
      }, []);

        function removeView(){
            props.removeView();
        }
        //,paddingTop:50,paddingBottom:50
  //      return  <View style={[{backgroundColor:'rgba(52, 52, 52, 0.8)',paddingTop: 10}]}> 
 return   <View style={[{backgroundColor:'rgba(52, 52, 52, 0.8)',paddingTop:50,paddingBottom:50}]}> 

            <View style={{borderRadius:8,backgroundColor:"#ffffff",width:1133,height:720,alignSelf:"center"}}>

<View style={{flexDirection:"row",width:"100%",borderBottomColor:"#E8E9ED",borderBottomWidth:1,height:70,alignItems:"center"}}>
<TouchableOpacity style={{}} onPress={() => removeView()}> 
<View style={{backgroundColor:"#FCF5EF",width:93,height:70,flexDirection:"row",borderRightColor:"#E0E3F7",borderRightWidth:1,borderTopLeftRadius:8,alignItems:"center"}}>

<Image
source={require('../assets/images/closecrossicon.png')}
style={{width:10,height:10,marginRight:8,marginLeft:23,}}     />
<Text style={{fontFamily:"Lato",fontSize:13,color:"#1F2E99"}}>Close</Text>


</View></TouchableOpacity>


      <Text style={styles.title3}>Day {data?.dayno} / Week {getweek(data?.dayno)} / {woundtype[data?.phototypeid]}</Text>


<View style={{position:"absolute",right:25.44}}>

<TouchableOpacity style={{marginLeft:11}} onPress={()=>pinpatientmedia(0)} >

{(((data?.ispinned)&&(isPinned=== null))||(isPinned==1))&&<Image
source={require('../assets/images/pinselectedicon.png')}
style={{width:15.56,height:20}}/>}
</TouchableOpacity>

<TouchableOpacity style={{marginLeft:11}} onPress={()=>pinpatientmedia(1)} >


{(((!data?.ispinned)&&(isPinned=== null))||(isPinned==0))&&<Image
source={require('../assets/images/pinicon.png')}
style={{width:15.56,height:20}}/>}
</TouchableOpacity>




</View>

</View>

<View style={{flexDirection:"row",}}>


<Image
source={{uri:global.imagepathurl+encodeURI(data?.photourl)}}


style={{width:650,height:650,borderBottomLeftRadius:8}}     />

<View style={{width:483}}>
<View style={{flexDirection:"row",alignItems:"center",paddingLeft:24,borderBottomColor:"#E8E9ED",borderBottomWidth:1,height:68}}>
<Image source={require('../assets/images/commenticon.png')}
style={{width:20,height:20}}     />
<Text style={styles.title1}>Comments ({data?.patientmediacomments.length})</Text>

{false&&<View style={{position:"absolute",right:25}}>
<TouchableOpacity  >
<Image source={require('../assets/images/searchicon.png')}
style={{width:20,height:20}}     />
</TouchableOpacity>
<TouchableOpacity  >
<Image source={require('../assets/images/commenticon.png')}
style={{width:20,height:20}}     />
</TouchableOpacity>

</View>}
</View>


{data?.patientmediacomments&&<FlatList   

ref={ref =>flatList = ref}
initialNumToRender={1} 
onLayout={() =>flatList.scrollToEnd({animated: false})}

onContentSizeChange={() => flatList.scrollToEnd({animated: false})}

 style={{width:"100%",height:514,overflow:"scroll",borderBottomColor:"#E8E9ED",borderBottomWidth:1,marginBottom:68}}

 data={data?.patientmediacomments}
  renderItem={({ item, index, separators }) => (
  
    <Comment photourl={item?.clinician?.photourl} fullname={item?.clinician?.firstname+" "+item.clinician?.lastname} date={item?.createdAt} 
    msg={item.message}
    />
   

  )}
/>}

<View style={{position:"absolute",right:0,bottom:0,height:68,alignItems:"center",backgroundColor:"#ffffff",width:"100%",borderBottomRightRadius:8,}}
>
{"p"==""&&<Text style={{fontFamily:"Lato",fontSize:15,color:"#959DAD",position:"absolute",left:24,marginTop:25}}>Add comments</Text>}

<TextInput editable={!sendingData}
  onSubmitEditing={sendComment}

  style={[{fontFamily:"Lato",fontSize:15,color:"#959DAD", textAlign: "left",position:"absolute",left:24,marginRight:24,width:392,marginTop:25  }]} keyboardType="email-address"
  maxLength={50}
  placeholder="Add comments"  
placeholderTextColor={"#959DAD"}
  numberOfLines={1}
  onChangeText={(text) => setCommenttext (text.trim())}

  value={commenttext}   /> 

<TouchableOpacity style={{position:"absolute",right:13,top:13}}
onPress={sendComment}
>
        <Image
source={require('../assets/images/sendcommentbutton.png')}
style={{width:42,height:42}}/>
            </TouchableOpacity>
  

</View>



</View>
</View>

</View>

            </View>;
    }
        
        export const WoundHeader = ({ title,style,props }) => (


<View style={style}>
        <Text style={styles.title1}>{title}</Text>
 </View>
   );

   const woundtype=["","Full","Top","Bottom"]

        export const WoundItem = ({item,fadeIn}) => (



            <View style={[styles.whitebox,{width:268,height:316,marginLeft:20,marginTop:20,           }]}>
     
     <View style={{flexDirection:"row",justifyContent:"space-between",marginTop:16,marginLeft:14,marginRight:15,alignItems:"center"}}>
        <Text style={styles.title2}>Day {item.dayno}  / {woundtype[item.phototypeid]}</Text>

        <View style={{flexDirection:"row",alignItems:"center"}}>
 

{(item.iscommented==1)&&<Image
source={require('../assets/images/commentselectedicon.png')}
style={{width:14,height:14,marginLeft:8}}/>}
{!(item.iscommented==1)&&<Image
source={require('../assets/images/commenticon.png')}
style={{width:14,height:14,marginLeft:8}}/>}

{(item.ispinned==1)&&<Image
source={require('../assets/images/pinselectedicon.png')}
style={{width:10.89,height:14,marginLeft:8}}/>}
{!(item.ispinned==1)&&<Image
source={require('../assets/images/pinicon.png')}
style={{width:10.89,height:14,marginLeft:8}}/>}



        </View>
        </View>
<TouchableOpacity onPress={fadeIn} >

<Image
source={{uri:global.imagepathurl+encodeURI(item?.photourl)}}

style={{width:268,height:268,borderBottomLeftRadius:8,borderBottomRightRadius:8,marginTop:16}}/>
            </TouchableOpacity>
            </View>
     // return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
            );
    

const styles = StyleSheet.create({
    whitebox:{

        backgroundColor:"#ffffff",borderRadius:8,
        shadowColor: "#818181",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
    
        elevation: 5,
    },
    title1:{
        marginLeft:20,
        color:"#1F2E99",
        fontFamily:"LatoBold",
        fontSize:18
    }  ,
     title2:{
        color:"#000E29",
        fontFamily:"Lato",
        fontSize:13
    } ,
    title3:{
       color:"#000E29",
       fontFamily:"Lato",
       fontSize:15,marginLeft:21
   }, 
  });
  