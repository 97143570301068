import * as React from 'react';
import { Text, TextProps,View,StyleSheet,ImageBackground ,Dimensions,Image,TouchableOpacity} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

//export function OrangeButton(props) {
        export const MessageSummary = ({ title }) => (



  <View style={{marginTop:17,marginBottom:25}} >
  <View style={{flexDirection:"row",alignItems:"center",marginBottom:18}}>
  <View style={{width:36,height:36,marginRight:15}}><Image
source={require('../assets/images/hospitallogo.png')}
style={{width:36,height:36}}     />
<View style={{
             position: 'absolute',
            right: -3,
            top: 0,
            backgroundColor: '#1F2E99',
            borderRadius: 6,
            width: 12,
            height: 12,
            justifyContent: 'center',
            alignItems: 'center'
     
          }}></View></View>

<Text style={{fontFamily:"LatoBold",fontSize:13,color:"#000000"}}>Alan Smith</Text>
    <View style={{width:16,height:16,borderRadius:6,marginLeft:10,marginRight:8,backgroundColor:"#FF6D6D"}}></View>
    
    <Text style={{fontFamily:"Lato",fontSize:13,color:"#646464"}}>14:20</Text>
    
   
    </View>
    <Text style={{fontFamily:"Lato",fontSize:13,color:"#1F2E99",paddingRight:24}}>This new exercise routine is working great for me, i would like to know if i should continue this for another few days.</Text>
  </View>
   
 // return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
        );

const styles = StyleSheet.create({
    listbutton:{paddingTop:13,alignItems:"center",
      height: 142,    width:350,  borderRadius:15, 
      backgroundColor : "#FE5935",
 
     alignContent:"center",
     padding:20,marginVertical:20,
  
      shadowColor: "#818181",
  shadowOffset: {
      width: 0,
      height: 16,
  },
  shadowOpacity: 0.2,
  shadowRadius: 5,
  
  elevation: 5,
  
    },
    videoicon:{marginTop:0,
      marginRight:18,
  width:34,
      height:24,
     
  
    },
    sendicon:{
      marginRight:20,
    width:27,
        height:27, },
        thumbsupicon:{
      marginRight:20,
    width:28,
        height:28, },


        startqa:{
      marginRight:20,
    width:20,
        height:20, },

        cameraicon:{
      marginRight:20,
    width:29,
        height:24, },



    nextarrow:{marginTop:7,
      marginLeft:20,
  width:20,
      height:11, },
      
  
  
  
    buttonshadowexit:{
      shadowColor: "#818181",
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.2,
      shadowRadius: 5,
      
      elevation: 5,
      
      backgroundColor:"transparent",
    
    },
    buttonshadowretake:{
  
      shadowColor: "#818181",
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.2,
      shadowRadius: 5,
      
      elevation: 5,
      backgroundColor:"transparent",
  
    },
    cameraexitbutton:{
      
      width:81,height:37,
    },
    cameraretakebutton:{
     
  
      width:117,height:37,
    },
    titlecamera1: {
     backgroundColor:"transparent",
         fontSize: 20,
       fontFamily: 'Lato',
       color:"#1F2E99",
       marginTop:25
  
    },  
    titlecamera2: {
      position:"relative",
      fontSize: 20, 
       fontFamily: 'LatoBold', 
       color:"#1F2E99",
  
    },
    scrollviewbottom:{
      height:180, flex:1,
      backgroundColor:"transparent",
  
    },
    scrollviewcontainter:{
      marginTop:250,
      backgroundColor:"transparent",
  
  
    },
  
   
    scrollView:{
      
    },
  
    card:{
      width:"10%",
      aspectRatio: 311/274,
      resizeMode: 'contain',
      marginTop:10,
   marginLeft:5
      
    },
    menuicon:{
      width:99,
      height:37,
      position:"absolute",
      top:38,
      left:"7.8%",
      
    },
    imageday:{
      width:93,
      height:37,
      position:"absolute",
      top:38,
      right:"3.8%",
      
    },
    titleday: {
      fontSize: 20,
      color: "#FE7235", 
      fontFamily: 'Questrial', 
      position:"absolute",
      top:45,
      right:"10%",
    },
    image: {
      flex: 1,
      resizeMode:"contain",
      width: '100%',
      height:'100%',
    },
    container: {
      backgroundColor:"#FCF5EF",
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 20,
      color: "#1F2E99", 
      fontFamily: 'Montserrat', 
       
    },
  
    
    separator: {
      marginVertical: 30,
      height: 1,
     
      width: '80%',
    },
  });
  