import React, { useState, useEffect } from 'react';
import { Animated,Text, TextInput,TextProps,View,StyleSheet,ImageBackground ,Dimensions,Image,TouchableOpacity} from 'react-native';

import { Hairline } from './Hairline';
import { OrangeBorderButton } from './OrangeBorderButton';
import { A } from '@expo/html-elements';
import  {Avatar}  from '../components/Avatar'

//export function OrangeButton(props) {

      const ProfileClinician=(props) => {
        

      const [data, setData] = React.useState(null);

     function  logOut(){
props.userStore.logout();

      }



    useEffect(() => {
      async function fetchData() {
        let tokenparam = 'None';
        console.log(props);
        console.log(props.userStore);
        if (props.userStore.sessiontoken) { tokenparam = 'Token ' + props.userStore.sessiontoken }
        fetch(global.apiurl + '/clinicians/getprofile', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenparam,
          }
        }).then((response) => response.json())
          .then(resData => {
            console.log(resData);
            if (resData.result) {
              setData(resData.user);
            } else {
            }
  
          }).catch(err => {
            console.log(err);
         
          });
      }
      fetchData();
    }, []);

    return  <View>

<Text style={styles.title1a}>Your profile</Text>
      <View style={{flexDirection:"row",marginTop:32}}>

      <View style={[styles.whitebox,{width:268,height:298,  alignItems:"center" }]}>

    <View style={{flexDirection:"row",marginTop:32}}>
         <Text style={styles.title2}>Profile picture</Text>
    <Image
source={require('../assets/images/infobutton.png')}
style={{width:16,height:16,marginLeft:7}}     />
    </View> 


    <Avatar url={data?.photourl&&(global.imagepathurl+encodeURI(data?.photourl))} size={132} radius={66} firstname={data?.firstname} lastname={data?.lastname} style={{borderColor:"#0077FF",borderWidth:1,marginTop:18,}}  />


<OrangeBorderButton    onPress={() => logOut()}   title="Log Out" width={144} style={{marginTop:26}}   />

      </View>


      <View style={[styles.whitebox,{width:652,marginLeft:20,marginBottom:154,alignItems:"flex-start" }]}>
      <Text style={styles.title3}>Profile details</Text>
     

     <Hairline marginTop={24}/>

<Text style={styles.title4}>Personal details</Text>

<TouchableOpacity style={{alignItems:"center",flexDirection:"row",position:"absolute",right:23,top:28}}>
<A href="/updateprofile">
<Image
                  source={require('../assets/images/undoicon.png')}
                  style={styles.undoicon} />
                  <Text style={styles.title5}>Edit details</Text>
</A>
</TouchableOpacity>
      <View style={{marginTop:24,marginLeft:24}}>
      <Text style={styles.title2}>Full name</Text>
      <View style={[styles.shadowinput, ]} >
                <Text
                  style={[styles.postopinput, { textAlign: "left", width: 250 }]} 
               >
                 {data?.firstname} {data?.lastname} </Text>
     
</View>
        
      </View>  


      <View style={{flexDirection:"row",marginTop:24,marginLeft:24}}>
      <View style={{width:250}}>
      <Text style={styles.title2}>Phone number</Text>
      <View style={[styles.shadowinput, ]} >
                
      <Text
                  style={[styles.postopinput, { textAlign: "left", width: 250 }]} 
               >+44 {data?.gsm}</Text>

              </View>
      </View>
      <View style={{width:250,marginLeft:32}}>
      <Text style={styles.title2}>Email address</Text>
      <View style={[styles.shadowinput, { width: 250 }]} >
      <Text
                  style={[styles.postopinput, { textAlign: "left", width: 250 }]} 
               >{data?.email}</Text>
              </View>

      </View>
        
      </View> 

        
      <Hairline marginTop={24}/>


    <View style={{marginLeft:25}}>
          <Text style={[styles.title4,{marginTop:32,marginLeft:0}]}>Clinician details</Text>
          <Text style={[styles.title2,{marginTop:24}]}>Role</Text>
          <Text style={styles.title5}>Doctor</Text>
          <Text style={[styles.title2,{marginTop:24}]}>Assigned clinical team(s)</Text>
          <Text style={styles.title5}>RD team, BG team</Text>
   <Text style={[styles.title2,{marginTop:24}]}>Assigned Hospital</Text>
    
   <View style={{flexDirection:"row",marginTop:8}}>
       
   <Image
source={require('../assets/images/hospitallogo.png')}
style={{width:46,height:46,marginRight:8,marginBottom:32}}     />
       
       <Text style={[styles.title5,{lineHeight:46}]}>Kettering General Hospital</Text>
  
    </View> 

        
  
    </View>

  
    <TouchableOpacity style={{flexDirection:"row",alignItems:"center",position:"absolute",bottom:-38,left:30}}>
<Image
source={require('../assets/images/resetpasswordicon.png')}
style={{width:16.2,height:18}}     />
       
       <Text style={[styles.title5,{marginLeft:8.8}]}>Reset password</Text>
  
</TouchableOpacity>

</View>



      </View>
    </View>
    ;
 // return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}


export default ProfileClinician;

const styles = StyleSheet.create({
    postopinput: {
        flex:1,outlineWidth:0,
        color:"#1F2E99",fontFamily:"Lato",fontSize:15,
        marginRight:10,
       
      },
      shadowinput:{
            
        marginTop:15,
        height:48,
       flexDirection: 'row',

    
      },
    
      countryflag: {
        width: 26,
        height: 18,
        marginHorizontal: 16,
        marginVertical: 19
      },  
         undoicon: {
        width: 15.55,
        height: 14,
        marginRight:8.45
        
      }, 
    whitebox:{
    
    backgroundColor:"#ffffff",borderRadius:8,
    shadowColor: "#818181",
    shadowOffset: {
    width: 0,
    height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    
    elevation: 5,
    },
      title1: {
        fontSize: 32,
        fontFamily: 'LatoBold',
    color:"#1F2E99",
    marginTop:40
    
      },
      title1a: {
        fontSize: 24,
        fontFamily: 'LatoBold',
    color:"#1F2E99",
    marginTop:32
    
      },
      title2: {
        fontSize: 13,
        fontFamily: 'Lato',
    color:"#798599",
    
      }, 
       title3: {
        fontSize: 18,
        fontFamily: 'LatoBold',
    color:"#1F2E99",
    marginTop:24,
    marginLeft:24
      },
        title4: {
        fontSize: 15,
        fontFamily: 'LatoBold',
    color:"#1F2E99",
    marginTop:24,
    marginLeft:24
    
      },
      title5: {
        fontSize: 15,
        fontFamily: 'Lato',
    color:"#1F2E99",
      },
    
      bgimage: {
        backgroundColor:"#36C2D5",
        borderColor: "#F2F3F7",
        borderWidth:1,
        borderRadius:66,
        width: 132,
        height:132,
        aspectRatio:1,
    marginTop:18,
    alignItems:"center",
    justifyContent:"center"
    
      },
  });
  