import React, { useState, useEffect } from 'react';

import { View,Text ,StyleSheet,Image,TouchableOpacity,TextInput} from 'react-native';

import  UpdateClinician  from '../components/UpdateClinician';
import { MainFrame } from '../components/MainFrame';
import { inject, observer } from 'mobx-react';

  const UpdateProfileScreen=inject("userStore")(observer(({userStore,...props})=> {

     

  return (
    <View style={styles.container}>
      <MainFrame userStore={userStore} >

     <UpdateClinician userstore={userStore}/> 

        
      </MainFrame>
    


    </View>
  );
}))

export default  UpdateProfileScreen;


const styles = StyleSheet.create({
 
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
  
  },

  
});
