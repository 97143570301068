import {Animated,Dimensions,TouchableHighlight,TouchableOpacity,View,Text ,StyleSheet,TextInput,Image,FlatList} from 'react-native';
import React, {useCallback, useState, useEffect } from 'react';

import { InvitePatient } from '../components/InvitePatient';
import { BlueButton } from '../components/BlueButton';
import { Hairline } from '../components/Hairline';
import { PatientFilter } from '../components/PatientFilter';
import { Avatar } from '../components/Avatar';
import { PatientRecoveryStatus } from '../components/PatientRecoveryStatus';
import { MainFrame } from '../components/MainFrame';
import Moment from 'moment';

import { StackActions } from '@react-navigation/native';
import { useNavigation, } from '@react-navigation/native';
import { A } from '@expo/html-elements';
import Modal from 'modal-react-native-web';

  import { inject, observer } from 'mobx-react';




    const TempScreen=inject("userStore")(observer(({userStore,props})=> {
      const [searchtext, setSearchtext] = React.useState("");
      const [filterHip,setFilterHip]= React.useState(false);
      const [filterKnee,setFilterKnee]= React.useState(false);
      


  const [invitemodalvisible, setInvitemodalvisible] = React.useState(false);
  const dimensions = Dimensions.get('window');
  const [data, setData] = React.useState(null);
  const [tempData, setTempData] = React.useState(null);


  const opacity = new Animated.Value(1);



  const theme = {
    datapicker:{
      backgroundColor:"#ff0000"
    },

    Button: {
      // These properties can be overwritten by defining them on the
      // button itself.
      small: true,
      auto: true,
      flat: false,
      type: 'navy',
      radius: true,
    },
    MainContainer: {
      style: StyleSheet.create({
        mainContainer: {
          justifyContent: 'space-between',
        },
      }).mainContainer,
    },
    Link: {
      type: 'navy',
    },
    Text: {
      // Instead of being overwritten by the prop style defined on the
      // text itself, the style property is the only property that is
      // prepended to the component prop.
      //
      // Example:
      // <Text style={{ fontSize: 20 }}>
      //   Something!
      // </Text>
      //
      // In this example, Text will have the following style:
      // [
      //   {
      //     lineHeight: 28
      //   },
      //   {
      //     fontSize: 20,
      //   }
      // ]
      type: 'gray',
      style: StyleSheet.create({
        text: {
          lineHeight: 28,
        },
      }).text,
    },
    Title: {
      style: StyleSheet.create({
        title: {
          fontSize: 30,
          paddingTop: 20,
          paddingBottom: 20,
        },
      }).title,
    },
    colors: {
      text: 'gray',
      primary: 'navy',
      gray: StyleSheet.create({
        background: { backgroundColor: '#1B2733' },
        border: { borderColor: '#1B2733' },
        text: { color: '#1B2733' },
      }),
      lightGray: StyleSheet.create({
        background: { backgroundColor: '#637282' },
        border: { borderColor: '#637282' },
        text: { color: '#637282' },
      }),
      navy: StyleSheet.create({
        background: { backgroundColor: '#0404f5' },
        border: { borderColor: '#0404f5' },
        text: { color: '#0404f5' },
      }),
      navySidemenu: StyleSheet.create({
        background: { backgroundColor: '#0404f5' },
        border: { borderColor: '#0404f5' },
        text: { color: '#0000a0' },
      }),
    },
  };
  
  const navigation = useNavigation();
  
  const history = {
    location: {
      pathname: () => "pp"      ,
    },
    push: routeName => navigation.navigate(routeName),
    replace: routeName => navigation.dispatch(
      StackActions.replace(routeName),
    ),
  };

  function calculateRecoveryTime(operationdate){
    //12 days / week 2
    var given = Moment(operationdate.replace("'",""));
    var current = Moment().startOf('day');
    var totalday=Moment.duration(current.diff(given)).asDays();
    if (totalday>0)
    return totalday+" days / week "+(1+Math.floor((totalday-1)/7));
     
    return "-";
    }
 
 function fadeIn () {


 setInvitemodalvisible(true);


  };
  
    function fadeOut () {
      Animated.timing(opacity, {
          toValue: 0,
          duration: 175
        }).start(()=>setInvitemodalvisible(false));
       fetchData();
    };


    function updateSearch(text){
      setSearchtext(text);
      if (('' == text)&&(!filterKnee)&&(!filterHip)) {
        console.log("updatesearch:null");
        setData(tempData);
        return;
    }
    setData(tempData.filter(function(item){
        
        if (!(filterKnee&&filterHip)){
          if (filterKnee&&item.operationtype!=1) return false;
          if (filterHip&&item.operationtype!=2) return false;
        }

      return (item.firstname+" "+item.lastname+" "+item.clinicalteam.name).toLowerCase().includes(text.toLowerCase());
    })
  
  );
    }

    const memoizedCallback = 
      (knee,hip) => {


        console.log("searchtext:"+searchtext);
        console.log("filterHip:"+filterHip);
        console.log("filterKnee:"+filterKnee);

        if (('' == searchtext)&&(!hip)&&(!knee)) {
          setData(tempData);
          return;
      }
     
      setData(tempData.filter(function(item){

      if (!(knee&&hip)){
          if (knee&&item.operationtype!=1) return false;
          if (hip&&item.operationtype!=2) return false;
        }
 
        return (item.firstname+" "+item.lastname+" "+item.clinicalteam.name).toLowerCase().includes(searchtext.toLowerCase());
      })
    
    );

    userStore.updatescreen();


      };


   

    async function fetchData() {
      let tokenparam = 'None';
      
      if (userStore.sessiontoken) { tokenparam = 'Token ' + userStore.sessiontoken }
      fetch(global.apiurl + '/clinicians/getpatients', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': tokenparam,
        }
      }).then((response) => response.json())
        .then(resData => {
          console.log(resData);
          if (resData.result) {
            setData(resData.data);
            setTempData(resData.data);
          } else {
          }

        }).catch(err => {
          console.log(err);
       
        });
    }

    useEffect(() => {
    
      fetchData();
    }, []);



    /**
     * 
    [{ title: 'Title Text', key: 'item1' },{ title: 'Title Text', key: 'item2' },{ title: 'Title Text', key: 'item13' }]
     */
  return (

    <View style={styles.container}>
        <Modal         
  onBackdropPress={() => setInvitemodalvisible(false)}
          animationType="fade"
          transparent={true}
          visible={invitemodalvisible}
          onDismiss={() => {
            fetchData();
          //  alert('Modal has been closed.');
          }}>


{!!invitemodalvisible&&  <Animated.View  style={
            {
              position:"absolute",top:0,zIndex:100,width:"100%",
                opacity:opacity,
                height:Math.max(dimensions.height,826)
             
            }
          }
        >
             

          <InvitePatient  userStore={userStore}  onFinish={fadeOut} visible={invitemodalvisible} removeView={fadeOut}></InvitePatient></Animated.View>
    }
     </Modal>

      <MainFrame userStore={userStore}  patientlist style={{}}>
<View style={{alignItems:"flex-start",width:1000}}>
      
<Text style={[styles.title1]}  >List of patients</Text>
<Text style={[styles.title2]} >{data?.length} Patients</Text>
<BlueButton onPress={()=>fadeIn()} invitenewuser title="Invite new patient" style={{position:"absolute",right:0,top:32,width:180}} />
<View style={{flexDirection:"row",alignItems:"center",marginTop:24}}>
<View style={[styles.shadowinput, { width: 267 }]} >
  <TextInput
  style={[styles.postopinput, { textAlign: "left", width: 220 }]} keyboardType="email-address"
  maxLength={50}
  numberOfLines={1}
  onChangeText={text => updateSearch(text)}
  value={searchtext}   /> 
<TouchableOpacity  style={{ position:"absolute",right:10,top:13 }}>
  <Image
    source={require('../assets/images/searchicon.png')}
    style={styles.searchicon} />
</TouchableOpacity>
</View>
<PatientFilter  setFilterKnee={x=>{setFilterKnee(x);memoizedCallback(x,filterHip);    }}  setFilterHip={x=>{setFilterHip(x);memoizedCallback(filterKnee,x); }} filterKnee={filterKnee} filterHip={filterHip}   />
</View>
<View style={{flexDirection:"row",width:"100%",justifyContent:"space-between",marginTop:24.5}}>
<TouchableOpacity style={{flex:0.30}}><Text style={[styles.title3]}>Name</Text></TouchableOpacity>
  <TouchableOpacity  style={{flex:0.177}}><Text style={[styles.title3]}>Clinical team</Text></TouchableOpacity>
  <TouchableOpacity  style={{flex:0.177}}> <Text style={[styles.title3]}>Recovery time</Text></TouchableOpacity>
  <TouchableOpacity  style={{flex:0.13}}><Text style={[styles.title3,styles.titlecenter]}>Compliance</Text></TouchableOpacity>
  <TouchableOpacity  style={{flex:0.177}}><Text style={[styles.title3,styles.titlecenter]}>Watch closely</Text></TouchableOpacity>
  <TouchableOpacity style={{flex:0.13}}><Text style={[styles.title3]}>Recovery status</Text></TouchableOpacity>
  <TouchableOpacity style={{flex:0.08}}></TouchableOpacity>
</View>
<Hairline marginTop={15.5}/>
<FlatList extraData={data}
 style={{width:"100%"}}
  data={data}
  renderItem={({ item, index, separators }) => (<A href={"patient?patientid="+item.id} style={{"text-decoration":"none"}}>
    <View style={{flexDirection:"row",width:"100%",justifyContent:"space-between",marginTop:24.5,backgroundColor:"#ffffff",height:94,alignItems:"center",borderRadius:6,}}>
 <View style={{flex:0.10,}}>
    <Avatar url={item?.photourl&&(global.imagepathurl+encodeURI(item?.photourl))} size={46} radius={23} firstname={item.firstname} lastname={item.lastname} style={{marginLeft:24,borderWidth:1,marginRight:18}} />
    </View>
  <View style={{flex:0.20}} ><Text style={[styles.title4]}>{item.firstname} {item.lastname}</Text>  
  <View style={{flexDirection:"row",alignItems:"center",marginTop:8}}>
  <Image
    source={require('../assets/images/unreadmsgicon.png')}
    style={styles.unreadmsgicon} />
  {item.inviteaccepted&&<Text style={{color:"#FE5935",fontFamily:"Lato",fontSize:13,marginLeft:6}}>1 unread messages</Text>}
  {!item.inviteaccepted&&<Text style={{color:"#FE5935",fontFamily:"Lato",fontSize:13,marginLeft:6}}>Invitation sent</Text>}
  </View></View>
  <TouchableOpacity style={{flex:0.177}}><Text style={[styles.title4]}>{item?.clinicalteam?.name}</Text></TouchableOpacity>
  <View style={{flex:0.177}} ><Text style={[styles.title4]}>{calculateRecoveryTime(item.operationdate)}</Text></View>
  <View style={{flex:0.13}} ><Text style={[styles.title4,styles.titlecenter,83>75&&{color:"#ED0000"}]}>{item.compliance?item.compliance+" %":""} </Text></View>
  <View style={{flex:0.177,alignItems:"center"}}>  {(item.watchclosely==1)&&<Image
    source={require('../assets/images/watchcloselycircleicon.png')}
  style={styles.watchcloselycircleicon} />}  </View>
  <View style={{flex:0.13}}> <PatientRecoveryStatus status={item.recoverystatus} />   </View>
  <View style={{flex:0.08,alignItems:"center"}}>
  {(item.ispinned==1)&&<Image
source={require('../assets/images/flagicon.png')}
style={styles.flagicon}/>}
 {!(item.ispinned==1)&&<Image
source={require('../assets/images/flagemptyicon.png')}
style={styles.flagicon}/>}
     </View>
</View>
</A>)}
/>




</View>
      </MainFrame>
    


    </View>
  );
}

))
    
export default  TempScreen;


const styles = StyleSheet.create({


  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: '#F194FF',
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },


  searchicon:{
    marginTop:2,
    width:12.33,
    height:13.33

  }, 
    watchcloselycircleicon:{
    width:15,
    height:15

  }, 
  unreadmsgicon:{
    width:8,
    height:8

  },  
  flagicon:{
    width:15,
    height:18.333

  },  
  
  postopinput: {
    flex:1,outlineWidth:0,
    color:"#1F2E99",fontFamily:"Lato",fontSize:15,
    marginRight:10,
    marginLeft:20,
  },
  shadowinput:{
        
    marginTop:15,
    height:44,
   flexDirection: 'row',borderRadius:15,
    shadowColor: "#818181",
    backgroundColor:"#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    
    elevation: 5,

  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
   
    height:"100%"
  },
  title1: {
    fontSize: 24,
    fontFamily: 'LatoBold',
color:"#1F2E99",

marginTop:32
  },
  title2: {
    marginTop:12,

    fontSize: 15,
    fontFamily: 'Lato',
color:"#1F2E99",

  }, 
    title3: {
    fontSize: 13,
    fontFamily: 'Lato',
color:"#1F2E99",

  },
  titlecenter:{width:"100%",textAlign:"center"},
     title4: {
    fontSize: 15,
    fontFamily: 'Lato',
color:"#000000",

  }, 
   title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  
});
