import { StatusBar } from 'expo-status-bar';

import React, { useEffect } from 'react';
import  {
  MenuProvider,
 
} from 'react-native-popup-menu';

import {Platform, StyleSheet, Text, View,Image,Linking } from 'react-native';
import Navigation from './navigation/';
import { Provider } from 'mobx-react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useFonts } from '@expo-google-fonts/inter';
import userStore from './mobx/store'
import useCachedResources from './hooks/useCachedResources';


export default function App() {

  let style = document.createElement("style");
style.setAttribute("type", "text/css");
style.appendChild(document.createTextNode(`
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
`));
document.body.appendChild(style);


  let [fontsLoaded] = useFonts({
    'Montserrat': require('./assets/fonts/Montserrat-Regular.ttf'),
    'MontserratMedium': require('./assets/fonts/Montserrat-Medium.ttf'),
    'MontserratSemiBold': require('./assets/fonts/Montserrat-SemiBold.ttf'),
    
    'Lato': require('./assets/fonts/Lato-Regular.ttf'),
    'LatoBold': require('./assets/fonts/Lato-Bold.ttf'),
    'Questrial': require('./assets/fonts/Questrial-Regular.ttf'),
  }); 

  const ref = React.useRef();


 
  const isLoadingComplete = useCachedResources();


  
  if (!isLoadingComplete) {
    return null;
  } else {


    
    
    return (
      <Provider userStore={userStore}  >

      <SafeAreaProvider >
      <MenuProvider>

        <Navigation  />
        <StatusBar />
        </MenuProvider>
      </SafeAreaProvider>

      </Provider>
 
    );
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
