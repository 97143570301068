import * as React from 'react';
import { Text,View,StyleSheet ,Dimensions,Image,TouchableOpacity} from 'react-native';


//export function OrangeButton(props) {
    export const Hairline = (props) => {
       
    return   <View style={{marginTop:props.marginTop,height: StyleSheet.hairlineWidth, backgroundColor: '#E8E9ED',
    borderBottomColor: '#E8E9ED',
    borderBottomWidth:  StyleSheet.hairlineWidth,alignSelf:'stretch',
    width:"100%"}}></View>


    ;
}
