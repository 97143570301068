import * as React from 'react';
import { Text,View,StyleSheet ,Dimensions,Image,TouchableOpacity} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Menu, {
    MenuOptions,
    MenuOption,
    MenuTrigger,
    renderers,
  } from 'react-native-popup-menu';


    export const PatientFilter = (props) => {
        const { ContextMenu, SlideInMenu, Popover } = renderers;
       


return    <View style={{marginLeft:40,marginTop:8,flexDirection:"row",alignItems:"center"}}>
   
   <View style={{flexDirection:"row",alignItems:"center",height:44,marginRight:11}}>
    <Image
        source={require('../assets/images/filtericon.png')}
        style={styles.filtericon} />
    
        <Text style={[styles.title3,{    marginLeft:6.67}]}>Filter by</Text>
    </View>

    {props.filterKnee&&<TouchableOpacity  onPress={() => props.setFilterKnee(false)}   style={styles.title1}  >
        <Text style={[styles.title3]}>Knee patients</Text><Image
    source={require('../assets/images/filtercloseicon.png')}
    style={styles.filtercloseicon} />   </TouchableOpacity>}


{props.filterHip&&<TouchableOpacity  onPress={() => props.setFilterHip(false)}   style={styles.title1}  >
        <Text style={[styles.title3]}>Hip patients</Text><Image
    source={require('../assets/images/filtercloseicon.png')}
    style={styles.filtercloseicon} />   </TouchableOpacity>}

    <Menu 
          renderer={Popover} rendererProps={{placement:"bottom"}}
          
        >
          <MenuTrigger   ><Text style={[styles.title3,{paddingVertical:6,paddingHorizontal:11 ,   borderRadius:4,backgroundColor:"#FCFCFC",border: "0.75px dashed #1F2E99"}]}>+ Add new filter</Text>

          </MenuTrigger>
          <MenuOptions customStyles={{borderRadius:8}} >
            <MenuOption text='Knee Patients'
              onSelect={() => {props.setFilterKnee(true);}} />
              <MenuOption text='Hip Patients'
              onSelect={() => {props.setFilterHip(true);}} />
            
            
          </MenuOptions>
        </Menu>
    
    </View>
    ;
 // return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}

const styles = StyleSheet.create({
    title1:{
        paddingVertical:6,paddingLeft:12,paddingRight:9.27,borderRadius:4,backgroundColor:"#E0E3F7"
    ,marginRight:8,flexDirection:"row",alignItems:"center"
    },
    filtercloseicon: {
        width: 8,
        height: 8,
        marginLeft:7
        ,marginTop:3
    },
    title2: {
        fontSize: 13,
        fontFamily: 'Lato',
    color:"#1F2E99",
    padding:10,
    
      }, 
      title3: {
        fontSize: 13,
        fontFamily: 'Lato',
    color:"#1F2E99",
    
      },filtericon:{
        width:13.33,
        height:12
    
      },
  });
  
  const optionStyles = {
    optionTouchable: {
      underlayColor: 'red',
      activeOpacity: 40,
    },
    optionWrapper: {
      margin: 5,
    },
    optionText: {
      color: 'black',
    },
  };