import React, { useState, useEffect } from 'react';

import { View,Text ,StyleSheet,Image,TouchableOpacity,TextInput} from 'react-native';

import  ProfileClinician  from '../components/ProfileClinician';
import { MainFrame } from '../components/MainFrame';
import { inject, observer } from 'mobx-react';


  const ProfileScreen=inject("userStore")(observer(({userStore,...props})=> {

     

  return (
    <View style={styles.container}>
      <MainFrame userStore={userStore} >
     <ProfileClinician userStore={userStore}/>

        
      </MainFrame>
    


    </View>
  );
}))
export default  ProfileScreen;

const styles = StyleSheet.create({
 
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
  
  },

  
});
