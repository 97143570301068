import React, { useState, useEffect } from 'react';
import { FlatList,Image,View,Text ,StyleSheet, TouchableHighlight, TouchableOpacity, TextInput} from 'react-native';
import { A ,Table,TR,TD,TBody} from '@expo/html-elements';
import { MainFramePatient } from '../components/MainFramePatient';
import Hoverable from "../navigation/Hoverable";
import { Avatar } from '../components/Avatar';

import { inject, observer } from 'mobx-react';


  const TempScreen=inject("userStore")(observer((props)=> {
    const {userStore, route, ...leftOver} = props;

    const textInputRef = React.useRef();

  
    const {patientid} = props.route.params;
    const [data, setData] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);
     const [weight, setWeight] = React.useState(0);
   



     function updateweight() {

      if (!(weight>0)) return;
      console.log("updating profile");
      let tokenparam = 'None';
      if (props.userStore.sessiontoken) { tokenparam = 'Token ' + props.userStore.sessiontoken }
     
        fetch(global.apiurl+'/clinicians/setpatientweight', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenparam,

          },
          body: JSON.stringify({
            patientid: patientid,
            weight: weight,
        
          })
        })
          .then(res => {
            if (res.status === 422) {
  
              throw new Error('Request failed.');
            }
            if (res.status !== 200 && res.status !== 201) {
              console.log('Error!');
              throw new Error('Could not authenticate you!');
            }
            return res.json();
          })
          .then(resData => {
            console.log(resData);
           if (resData.result){
             console.log("DONE");
            
fetchData();  
  
  
            } else {
    
           alert("Unable change password\n\nPlease check your password");
          }
           
        
          })
          .catch(err => {
            console.log(err);
           
          });
      }


      async function fetchData() {
        let tokenparam = 'None';
        
        if (props.userStore.sessiontoken) { tokenparam = 'Token ' + props.userStore.sessiontoken }
        fetch(global.apiurl + '/clinicians/getpatient', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenparam,
          },
          body: JSON.stringify({
              patientid: patientid,
              
            })
        }).then((response) => response.json())
          .then(resData => {
            console.log(resData);
            if (resData.result) {
              setData(resData.data);
              setEditMode(false);

              setWeight(resData.data.weight);
            } else {
            }
  
          }).catch(err => {
            console.log(err);
         
          });
      }

    useEffect(() => {
       
        fetchData();
      }, []);

      function calculageage(birthday) { // birthday is a date
        var ageDifMs = Date.now() - Date.parse(birthday);
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        if (Math.abs(ageDate.getUTCFullYear() - 1970)>0)
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    
        return "-";
      }
    function calculateBMI(height,weight){
      if (Math.round(weight/height/height*100*100)>0)
      return Math.round(weight/height/height*100*100);

        return "-";

      
    }

  return (
    <View    style={styles.container}>
              {(data)&&<MainFramePatient userStore={userStore}  selected={1} patient={data}    patientlist style={{alignItems:"flex-start",flexDirection:"row",width:"100%",paddingLeft:0,paddingRight:0,paddingTop:0,}}>





          
           <View   style={{paddingLeft:63,paddingRight:63,paddingTop:20,alignSelf:"stretch" ,flexDirection:"row"}}>

<View style={[styles.whitebox,{flex:1,alignSelf:"stretch",height:674}]}>
<Text      
 style={[styles.title3,{marginTop:24,marginLeft:24}]}>Personal details</Text>

{!editMode&&<TouchableOpacity 
    style ={{position:"absolute",right:24,top:28}} 
    onPress={x=>{setEditMode(true); setTimeout(() => textInputRef.current.focus(), 200);}}  >
<Image style={{width:14.87,height:14.87}} source={require('../assets/images/editpenicon.png')}     />
</TouchableOpacity>}

{editMode&&<TouchableOpacity 
    style ={{position:"absolute",right:24,top:28}} 
    onPress={x=>{setWeight(data?.weight);setEditMode(false);}}  >
<Text style={styles.title8}>Cancel</Text>
</TouchableOpacity>}
{editMode&&<TouchableOpacity 
    style ={{position:"absolute",right:94,top:28}} 
    onPress={x=>updateweight()} 
    
    >
<Text style={styles.title8}>Save</Text>

</TouchableOpacity>}


<View style={{marginTop:24,height: StyleSheet.hairlineWidth, backgroundColor: '#00C3FF',
    borderBottomColor: '#00C3FF',
    borderBottomWidth:  StyleSheet.hairlineWidth,alignSelf:'stretch',
    width:"100%"}}></View>
<Table style={{marginLeft:26,width:390,marginTop:24,marginRight:26}}>
  <TBody>
<TR style={styles.title7}>
<TD >Gender</TD><TD>Age</TD><TD></TD></TR>
<TR style={styles.title6}><TD><Text>{data?.gender==1&&"Male"}{data?.gender==2&&"Female"}{data?.gender==3&&"Other"}</Text></TD><TD>{calculageage(data?.dateofbirth)}</TD><TD></TD></TR>
<TR style={styles.title7} ><TD>Height</TD><TD>
  
  <View style={{flexDirection:"row"}}>
  <Text>Weight</Text>
  <Hoverable
             
      > 
      {isHovered=>(<View><Image   
   style={{marginLeft:8,width:16,height:16}}  source={require('../assets/images/infobutton.png')}    />
   <Text style={[{ color: '#FFFFFF',position:"absolute",top:-63,left:18,textAlign:"center" },(isHovered && { backgroundColor:"#aaaaaa",borderRadius:10,padding:5 })]} > {"First weight:\n"+data?.initialweight} kg </Text>
   </View>
   )}</Hoverable>


</View>
  </TD><TD>BMI</TD></TR>
<TR style={styles.title6}><TD>{data?.height}cm</TD><TD>
  
  
{!editMode&&<Text>{data?.weight} </Text>}
  {editMode&&<TextInput ref={textInputRef}  maxLength={3} style={{width:40}} onChangeText={text => setWeight((text))}  value={weight}></TextInput>}kg



</TD><TD>{calculateBMI(data?.height,data?.weight)}</TD></TR>
<TR style={styles.title7}><TD>Phone number</TD><TD>Email address</TD><TD></TD></TR>
<TR style={styles.title6}><TD>+44 {data?.gsm}</TD><TD>{data?.email}</TD><TD></TD></TR>
<TR style={styles.title7}><TD>NHS number</TD><TD>Hospital number</TD><TD></TD></TR>
<TR style={styles.title6}><TD>{data?.nhsnumber}</TD><TD>{data?.hospital?.number}</TD><TD></TD></TR>
</TBody>
</Table>

<View style={{marginTop:56,height: StyleSheet.hairlineWidth, backgroundColor: '#00C3FF',
    borderBottomColor: '#00C3FF',
    borderBottomWidth:  StyleSheet.hairlineWidth,alignSelf:'stretch',
    width:"100%"}}></View>

<Table style={{marginLeft:26,width:400,marginTop:24}}>
<TBody>
<TR  style={styles.title7}><TD>Care-taker fullname</TD><TD></TD><TD></TD></TR>
<TR style={styles.title6}><TD>{data?.caretakerfirstname} {data?.caretakerlastname}</TD><TD></TD><TD></TD></TR>
<TR style={styles.title7} ><TD>Care-taker phone number</TD><TD>Care-taker email address</TD><TD></TD></TR>
<TR style={styles.title6}><TD>+44 {data?.caretakergsm}</TD><TD>{data?.caretakeremail}</TD><TD></TD></TR>
</TBody>
</Table>


</View>


<View style={[styles.whitebox,{flex:1,alignSelf:"stretch",height:674,marginLeft:20}]}>
<Text style={[styles.title3,{marginTop:24,marginLeft:24}]}>Clinical details</Text>
<View style={{marginTop:24,height: StyleSheet.hairlineWidth, backgroundColor: '#00C3FF',
    borderBottomColor: '#00C3FF',
    borderBottomWidth:  StyleSheet.hairlineWidth,alignSelf:'stretch',
    width:"100%"}}></View>

<Table style={{marginLeft:26,width:400,marginTop:24}}>
<TBody>
<TR style={styles.title7} ><TD>OP-type</TD><TD>OP-date</TD><TD>Clinical team responsible</TD></TR>
<TR style={styles.title6}><TD>{data?.operationtype==1&&"Knee"}{data?.operationtype==2&&"Hip"}</TD><TD>{data?.operationdate}</TD><TD>{data?.clinicalteam?.name}</TD></TR>
</TBody>
</Table>

<Text style={[styles.title8,{marginTop:48,marginLeft:24}]}>Clinical team members</Text>

<View style={{flexDirection:"row",width:"100%",justifyContent:"space-between",marginTop:22,marginBottom:20,alignItems:"flex-start",alignContent:"flex-start",borderBottomWidth:1,borderBottomColor:"#E6E6E6"}}>
 <View style={{width:42.80,marginLeft:24}}>
 <Text style={[styles.title11]}>Name</Text>
    </View>
  <View style={{flex:1}} > </View>
  <View style={{flex:1}} ><Text style={[styles.title11]}>Role</Text>  </View>
  </View>

<FlatList
 style={{width:"100%"}}
  data={data?.clinicalteam?.clinicians}
  
  
  renderItem={({ item, index, separators }) => (
  
    <View style={{flexDirection:"row",width:"100%",justifyContent:"space-between",height:64,alignItems:"center",alignContent:"center",borderBottomWidth:1,borderBottomColor:"#E6E6E6"}}>

    <Avatar url={item?.photourl&&(global.imagepathurl+encodeURI(item?.photourl))} size={32} radius={16} firstname={item.firstname} lastname={item.lastname} style={{marginLeft:24,marginRight:12.80}} />

  

  <View style={{flex:1}} ><Text style={[styles.title10]}>{item.firstname} {item.lastname}</Text> </View>
  <View style={{flex:1}} ><Text style={[styles.title10]}>Doctor</Text>  </View>
  
  

  </View>

  )}
/>


</View>



</View>
         <View>
         </View>
      </MainFramePatient>}
    </View>
  );
}))
export default  TempScreen;

const styles = StyleSheet.create({
    whitebox:{

        backgroundColor:"#ffffff",borderRadius:8,
        shadowColor: "#818181",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
    
        elevation: 5,
    },
title1:{
    fontFamily:"Lato",
    fontSize:13,
    color:"#1F2E99"
},title2:{
    fontFamily:"Lato",
    fontSize:15,
    color:"#1F2E99"
},
title3:{
    fontFamily:"LatoBold",
    fontSize:18,
    color:"#1F2E99"
},
title4:{
    fontFamily:"Lato",
    fontSize:13,
    color:"#868686"
},

title5:{
    fontFamily:"Lato",
    fontSize:13,
    color:"#1F2E99"
},
title6:{
    fontFamily:"Lato",
    fontSize:15,
    color:"#000000",
    height:50,
    alignItems:"flex-start",
    "vertical-align": "top",


},

title7:{
    fontFamily:"Lato",
    fontSize:13,
    color:"#868686",
    height:28,
},
title8:{
    fontFamily:"LatoBold",
    fontSize:15,
    color:"#1F2E99",
},
title9:{
    fontFamily:"Lato",
    fontSize:13,
    color:"#868686",
    height:64,
    
},
title10:{
    fontFamily:"Lato",
    fontSize:15,
 
    color:"#000000",



},
title11:{
    fontFamily:"Lato",
    fontSize:13,
    color:"#868686",
    height:28,
},
    container: {
        flex: 1
      },
     
  
});
