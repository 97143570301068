import React, { useState, useEffect } from 'react';
import { Text, TextProps,View,StyleSheet,ImageBackground ,Dimensions,Image,TouchableOpacity} from 'react-native';
import { Switch } from 'react-native-switch';
import Moment from 'moment';
import  {Avatar}  from '../components/Avatar'

import {Picker} from '@react-native-picker/picker';

//export function OrangeButton(props) {
    export const PatientHeader = (props) => {
        
        const [isPinned, setIsPinned] = useState(props?.patient?.ispinned);
        const [watchClosely, setWatchClosely] = useState(props?.patient?.watchclosely);
        const [recoveryStatus, setRecoveryStatus] = useState(props?.patient?.recoverystatus);


let plans=["rest","rer","okoko"];


        function togglePin(){

            pinpatient(props.patient.id,Math.abs(1-isPinned));
        
        }
        
        
        async function pinpatient(patientid,ispinned) {
            let tokenparam = 'None';
            if (global.token) { tokenparam = 'Token ' + global.token }
            fetch(global.apiurl + '/clinicians/pinpatient', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenparam,
              },
              body: JSON.stringify({
                  patientid: patientid,
                  ispinned: ispinned,
                  
                })
            }).then((response) => response.json())
              .then(resData => {
                console.log(resData);
                if (resData.result) {
                    setIsPinned(ispinned);
                } else {
                }
        
              }).catch(err => {
                console.log(err);
             
              });
          }
        


function toggleSwitch(){

    setwatchclosely(props.patient.id,Math.abs(1-watchClosely));

}


async function setwatchclosely(patientid,watchclosely) {
    let tokenparam = 'None';
    if (global.token) { tokenparam = 'Token ' + global.token }
    fetch(global.apiurl + '/clinicians/setpatientwatchclosely', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': tokenparam,
      },
      body: JSON.stringify({
          patientid: patientid,
          watchclosely: watchclosely,
          
        })
    }).then((response) => response.json())
      .then(resData => {
        console.log(resData);
        if (resData.result) {
            setWatchClosely(watchclosely);
        } else {
        }

      }).catch(err => {
        console.log(err);
     
      });
  }


async function setpatientrecoverystatus(patientid,recoverystatus) {
    let tokenparam = 'None';
    
    if (global.token) { tokenparam = 'Token ' + global.token }
    fetch(global.apiurl + '/clinicians/setpatientrecoverystatus', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': tokenparam,
      },
      body: JSON.stringify({
          patientid: patientid,
          recoverystatus: recoverystatus,
          
        })
    }).then((response) => response.json())
      .then(resData => {
        console.log(resData);
        if (resData.result) {
            setRecoveryStatus(recoverystatus);
        } else {
        }

      }).catch(err => {
        console.log(err);
     
      });
  }

function updateRecoveryStatus(itemIndex){
  
    setpatientrecoverystatus(props.patient.id,itemIndex);



}
function calculateRecoveryTime(operationdate){
//12 days / week 2
var given = Moment(operationdate.replace("'",""));
var current = Moment().startOf('day');
var totalday=Moment.duration(current.diff(given)).asDays();
if (totalday>0)
return totalday+" days / week "+(1+Math.floor((totalday-1)/7));

return "-";
}




    return      (
       
<View style={{height:106,paddingLeft:63,alignSelf:"stretch" }}>

<View style={{flexDirection:"row",marginTop:22}}>



<Avatar url={props.patient?.photourl&&(global.imagepathurl+encodeURI(props.patient?.photourl))} size={61} radius={16} firstname={props?.patient?.firstname} lastname={props?.patient?.lastname}  />


<View style={{alignSelf:"center",marginLeft:14}}>
<View style={{flexDirection:"row",marginTop:8}}>
    <Text style={styles.title3}>{props?.patient?.firstname} {props?.patient?.lastname} </Text>
  
  <TouchableOpacity style={{marginLeft:11}} onPress={()=>togglePin()} >
    {(isPinned==1)&&<Image
source={require('../assets/images/flagicon.png')}
style={{width:15,height:18.333}}/>}
 {!(isPinned==1)&&<Image
source={require('../assets/images/flagemptyicon.png')}
style={{width:15,height:18.333}}/>}
</TouchableOpacity>

</View>
<View style={{flexDirection:"row",marginTop:8}}> <Text style={styles.title4}>Recovery time </Text>
<Text style={styles.title5}>{calculateRecoveryTime(props.patient?.operationdate)}</Text></View>
</View>
</View>


<View style={{position:"absolute",right:63,top:39,flexDirection:"row",alignItems:"center"}}>
<Text style={{marginRight:8}}>Recovery status</Text> 

    
<Picker
   //style={{"-webkit-appearance":"none", "text-align":"center","text-align-last":"center","-moz-text-align-last":"center",width:76,height:24,borderRadius:20,fontFamily:"LatoBold",fontSize:13,color:"#ffffff",backgroundColor:"#6DD803",alignItems:"center",borderWidth:0}}
   style={[styles.select, (recoveryStatus==0)&&{backgroundColor:"#6DD803","text-indent": "18px"}
   , (recoveryStatus==1)&&{backgroundColor:"#F9CE37","text-indent": "24px"}
   , (recoveryStatus==2)&&{backgroundColor:"#FF6D6D","text-indent": "22px"}
   , (recoveryStatus==null)&&{backgroundColor:"#DDDDDD","text-indent": "8px"}
    ] }
  selectedValue={recoveryStatus}
  onValueChange={(itemValue, itemIndex) =>{
  updateRecoveryStatus(itemIndex)
  }}  >
  
  {(recoveryStatus==null)&&<Picker.Item label="Not Set" />}
  <Picker.Item label="Good" value="0" />
  <Picker.Item label="Fair" value="1" />
  <Picker.Item label="Poor" value="2" />


</Picker>

<TouchableOpacity><Image
source={require('../assets/images/statsicon.png')}
style={{width:28,height:28,marginLeft:8}}/>
</TouchableOpacity>
<Text  style={{marginRight:12,marginLeft:36}}>Watch closely</Text>
<Switch
        trackColor={{ true: '#1F2E99', false: '#D3DAE1' }}
        thumbColor={watchClosely ? '#1F2E99' : '#1F2E99'}
        backgroundActive={"#6B84E9"}
        backgroundInactive={"#D3DAE1"}
        ios_backgroundColor="#1F2E99"
        onValueChange={toggleSwitch}
        value={watchClosely==1}
        
        renderActiveText={false}
        renderInActiveText={false}
        circleSize={19}
        barHeight={15}
        circleBorderWidth={0}
        circleActiveColor={"#1F2E99"}
    circleInActiveColor={"#1F2E99"}
      />

</View>
</View>
       
        );
    }
const styles = StyleSheet.create({
    
    select:{
      //  "text-align":"center","text-align-last":"center",
        "-moz-appearance": "none",
        "-webkit-appearance": "none",
        "appearance": "none",
        "backgroundImage":"url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAASCAQAAADm+6ZSAAAAaUlEQVR42p3UuxXAIAxDUY3kTWED2PSlIgfCz45KWbe1JAnDFEi3JwGQ3LS8e4yWEqAA1mMH7yiYJKqXDzS30sUHWvvDlW/pnR/pmV/pnrvomrvpzEN04jG64V664BH64VEqSeTf1PMkHuYTY4R7BntNAAAAAElFTkSuQmCC)",
        "backgroundPosition":"87% 50%",
        "backgroundSize":"10px 6px",
        "background-repeat": "no-repeat",borderWidth:0,
        width:76,height:24,borderRadius:20,fontFamily:"LatoBold",fontSize:13,color:"#ffffff",
 textAlign:"center"
    }
    ,
    title3:{
        fontFamily:"LatoBold",
        fontSize:18,
        color:"#1F2E99"
    },
    title4:{
        fontFamily:"Lato",
        fontSize:13,
        color:"#868686"
    },
    
    title5:{
        fontFamily:"Lato",
        fontSize:13,
        color:"#1F2E99"
    },
  });
  