import React, { useState, useEffect } from 'react';
import { FlatList,Image,View,Text ,StyleSheet, TouchableHighlight, TouchableOpacity} from 'react-native';

import { MainFramePatient } from '../components/MainFramePatient';
import { CarePlan,CarePlanWeekSelector } from '../components/CarePlan';

import { inject, observer } from 'mobx-react';

  const TempScreen=inject("userStore")(observer((props)=> {

  const {patientid} = props.route.params;
    const [data, setData] = React.useState(null);
    const [week, setWeek] = React.useState(1);
    function onPrev(){setWeek(Math.max(1,week-1))};
    function onNext(){setWeek(Math.min(60,week+1))};


    useEffect(() => {
        async function fetchData() {
          let tokenparam = 'None';
          if (global.token) { tokenparam = 'Token ' + global.token }
          fetch(global.apiurl + '/clinicians/getpatientpromsdata', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': tokenparam,
            },
            body: JSON.stringify({
                patientid: patientid,
                
              })
          }).then((response) => response.json())
            .then(resData => {
              console.log(resData);
              if (resData.result) {
                setData(resData);
              } else {
              }
    
            }).catch(err => {
              console.log(err);
           
            });
        }
        fetchData();
      }, []);



  return (
    <View style={styles.container}>
        {(data)&& <MainFramePatient  userStore={props.userStore}   selected={6} patient={data.patient}  patientlist style={{alignItems:"flex-start",flexDirection:"row",width:"100%"}}>
  
<View style={[styles.whitebox,{paddingBottom:7}]}> 
  
        <Text style={[styles.title3,{marginTop:23,marginLeft:24}]}>Schedule</Text>
    <CarePlanWeekSelector  style={{top:25,right:301}} week={week} onPrev={onPrev} onNext={onNext}/>
  <CarePlan  style={{left:-1,marginTop:24,overflow:"hidden",width:862}}  week={week}></CarePlan>

  </View>
        
      </MainFramePatient>}
    


    </View>
  );



}))
export default  TempScreen;

const styles = StyleSheet.create({
    whitebox:{

        backgroundColor:"#ffffff",borderRadius:8,
        shadowColor: "#818181",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
    
        elevation: 5,
    },
title1:{
    fontFamily:"Lato",
    fontSize:13,
    color:"#1F2E99"
},title2:{
    fontFamily:"Lato",
    fontSize:15,
    color:"#1F2E99"
},
title3:{
    fontFamily:"LatoBold",
    fontSize:18,
    color:"#1F2E99",
    
},

    container: {
        flex: 1
      },
     
  
});
