import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      PatientOverviewScreen: 'patient',
      PatientListScreen: 'patients',
      DashboardScreen: 'dashboard',
      PatientWoundsScreen: 'patientmedia',
      PatientPROMSScreen: 'patientproms',
      PatientPREMSScreen: 'patientprems',
      PatientGeneralquestionsScreen: 'patientgeneralquestions',
      PatientCarePlanScreen: 'patientcareplan',
      PatientMessagesScreen: 'patientmessages',
      PatientDiaryScreen: 'patientdiary',
      RegisterUpdateDetailsScreen: 'registerprofile',
      RegisterCode: 'register',
      LoginScreen: 'login',
      ResetPasswordScreen: 'resetpassword',
      UpdateProfileScreen: 'updateprofile',
      ProfileScreen: 'profile',
      TermsConditionsScreen: 'termsconditions',
      TempScreen3: 'TempScreen3',
      TeamScreen: 'team',
      Chat: 'feed/:sort',
      Profile: 'user'
    },
  },
};
