import * as React from 'react';
import { Text,View,StyleSheet ,Dimensions,Image,TouchableOpacity} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

    export const Avatar = (props) => {
        const insets = useSafeAreaInsets();

        const dimensions = Dimensions.get('window');
const bottomdistance=dimensions.height/25+insets.bottom;
    return     <TouchableOpacity 
    
    style={[styles.bgimage,{width:props.size,height:props.size},props.radius&&{borderRadius:props.radius},props.style]}

    onPress={props.onPress}  >
  
  {(props.url)&&<Image source={{uri:props.url}}
style={{width:props.size,height:props.size,borderRadius:props.radius}}/>}

{!(props.url) && <Text style={{fontFamily:"LatoBold",fontSize:props.size*36/132,color:"#FFFFFF"}} >{(" "+props.firstname).charAt(1).toUpperCase()}{(" "+props.lastname).charAt(1).toUpperCase()}</Text>}
 

   </TouchableOpacity>  
    ;
 // return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}

const styles = StyleSheet.create({
    bgimage: {
        backgroundColor:"#36C2D5",
        borderColor: "#F2F3F7",
        borderWidth:1,
        borderRadius:66,
        width: 132,
        height:132,
        aspectRatio:1,
    alignItems:"center",
    justifyContent:"center"
    
      }
  });
  