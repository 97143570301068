import * as React from 'react';
import {ImageBackground,TextInput,Dimensions, View,Text ,StyleSheet,Image,TouchableOpacity} from 'react-native';
import { A } from '@expo/html-elements';

import SplitFrame from '../../components/SplitFrame';
import { OrangeButton } from '../../components/OrangeButton';
import validate from '../validate_wrapper'
import { inject, observer } from 'mobx-react';


const Temp=inject("userStore")(observer(({userStore,navigation})=> {



    const [email, setEmail] = React.useState('');
    const [errorText, setErrorText] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [secure, setSecure] = React.useState(true);



    const dimensions = Dimensions.get('window');
   

    function validateForm(){
      setErrorText("");
        const passwordError = validate('password', password);
        const emailError = validate('email', email);
        console.log("xxx");
        
       
      
          if (!passwordError && !emailError) {
          checkinvitationcode();
          } else {
          //  alert("\n"+(emailError?"\n"+emailError+"\n":"")+(passwordError?"\n"+passwordError+"\n":"")+"\n");    
            setErrorText((emailError?"\n"+emailError+"\n":"")+(passwordError?"\n"+passwordError+"\n":""));    
            
          }
      }


      function checkinvitationcode() {
        fetch(global.apiurl+'/clinicians/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            password: password,
          
          })
        })
          .then(res => {
            if (res.status === 422) {
              setErrorText("Unable to verify,\nPlease check the your login details.");
              throw new Error('Validation failed.');

            }
            if (res.status !== 200 && res.status !== 201) {
              console.log('Error!');
              setErrorText("Unable to verify,\nPlease check the your login details.");

              throw new Error('Could not authenticate you!2');
            }
            return res.json();
          })
          .then(resData => {
            console.log(resData);
           if (resData.result){
            userStore.setLoggedin(resData.token,resData.photourl);


        } else {
          setErrorText("Unable to verify,\nPlease check the your login details.");

        //    alert("Please check the your invitation details.");

         //   Alert.alert("Unable to verify","Please check the your invitation details.");
          }
           
            /*this.setState({
              isAuth: true,
              token: resData.token,
              authLoading: false,
              userId: resData.userId
            });
            localStorage.setItem('token', resData.token);
            localStorage.setItem('userId', resData.userId);
            const remainingMilliseconds = 60 * 60 * 1000;
            const expiryDate = new Date(
              new Date().getTime() + remainingMilliseconds
            );
            localStorage.setItem('expiryDate', expiryDate.toISOString());
            this.setAutoLogout(remainingMilliseconds);*/
          })
          .catch(err => {
            console.log(err);
          /*  this.setState({
              isAuth: false,
              authLoading: false,
              error: err
            });*/
          });
      };
    


  return (    <SplitFrame>
<View style={{width:350,flexShrink:0.5,backgroundColor:"#ffffff"}}>
    <Image
source={require('../../assets/images/logopostop.png')}
style={{width:178,height:61,marginTop:93}}     />
    <Text style={styles.title1}>Login to your
Post Op account.
     </Text>


  <Text style={[styles.title5,{marginTop:48}]}>Email address</Text>
<View  style={styles.shadowinput} >
  

  <TextInput
      style={styles.phoneinput}  keyboardType="number-pad"
      onChangeText={text => setEmail((text.replace(' ', '')))}
      value={email}
      returnKeyType='done'
    />
    </View>
    
  <Text style={styles.title5}>Password</Text>
  <View  style={styles.shadowinput} >
  
    <TextInput returnKeyType='done'
        style={styles.passwordinput} keyboardType="email-address"
        secureTextEntry={secure} 
        onChangeText={(text) => setPassword (text.trim())}
        value={password}
       
      />

<TouchableOpacity onPress={() => setSecure(!secure)}>


      <Image  
       source={require('../../assets/images/passwordshow.png')}
       style={styles.passwordshow}     />
       </TouchableOpacity>

      </View>

      <View style={{flex: 1, marginTop:18}}>
<A href="resetpassword">
  <Text style={styles.title4}>Forgot password?</Text>
</A>
  </View>

      <View style={{justifyContent:"flex-end  ",position:"absolute",top:568,height:60}}>




<Text style={{color:"#FF6D6D"}}>{errorText}</Text></View>

<OrangeButton onPress={() => validateForm()} title="Login"  on style={{marginTop:93}} />


<View style={{flexDirection:"row",marginBottom:118,marginTop:16,width:350,justifyContent:"center"}}>
<Text style={{fontFamily:"Lato",fontSize:15,color:"#000000",marginLeft:30}}>

Have an account?</Text>
<A href="/register"><Text style={{fontFamily:"Lato",marginLeft:4,fontSize:15,color:"#0009EC",   textDecorationLine: "underline"}}>
Register
</Text></A>

      </View>
      </View>

   

</SplitFrame>
  );
}))

export default Temp;


const styles = StyleSheet.create({
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    height:"100%"
  },

  containersplit: {
    flex: 1,
    flexDirection:"row",
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  
  },
  phoneinput:{
    flex:1,outlineWidth:0,
    color:"#1F2E99",fontFamily:"Lato",fontSize:15,
    marginRight:10,
    marginLeft:20,
  },
  shadowinput:{
        
    marginTop:15,
    height:48,
   flexDirection: 'row',borderRadius:15,
    shadowColor: "#818181",
    backgroundColor:"#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    
    elevation: 5,

  },
  title1: {
    fontSize: 32,
    fontFamily: 'LatoBold',
color:"#1F2E99",
marginTop:39.6,
width:244

  },
  title2: {
      marginTop:18,
    fontSize: 15,
    fontFamily: 'Lato',
color:"#000000",

  }, 
   title3: {
    fontSize: 11,
    lineHeight: 14,
    color:"#000000",
    fontFamily: 'Lato',

  },
  title4:{
    fontSize: 13,
    lineHeight: 14,
    color:"#0009EC",
    fontFamily: 'Lato',
    textDecorationLine: "underline"
  },
  title5:{
      marginTop:32,
    fontSize: 13,
    color:"#798599",
    fontFamily: 'Lato',

  }, title6:{
      marginTop:8,
    fontSize: 11,
    color:"#798599",
    fontFamily: 'Lato',

  },
  passwordshow: {
    width: 24,
    height:15,
    position:"relative",
    top:20,
    right:15,
  },
 
  passwordinput:{
    width:"100%",
    flex:1,outlineWidth:0,
        color:"#1F2E99",fontFamily:"Lato",fontSize:15,
        marginRight:22,
        marginLeft:20,
    
      },
  
});
