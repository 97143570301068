import React, { useState, useEffect } from 'react';
import { TouchableOpacity,SectionList,View,Text ,StyleSheet,Dimensions,Image,Modal, TouchableHighlight} from 'react-native';

import { Hairline } from '../components/Hairline';
import { MessageSummary } from '../components/MessageSummary';
import { MessageSummaryHeader } from '../components/MessageSummaryHeader';
import { MainFrame } from '../components/MainFrame';
import { inject, observer } from 'mobx-react';

//export default function TempScreen({navigation}) {

  const DashboardScreen=inject("userStore")(observer(({userStore,navigation})=> {
    const [data, setData] = React.useState(null);


    useEffect(() => {
      async function fetchData() {
        let tokenparam = 'None';
        
        if (global.token) { tokenparam = 'Token ' + global.token }
        fetch(global.apiurl + '/clinicians/getdashboard', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenparam,
          }
        }).then((response) => response.json())
          .then(resData => {
            console.log(resData);
            if (resData.result) {
              setData(resData.data);
            } else {
            }
  
          }).catch(err => {
            console.log(err);
         
          });
      }
      fetchData();
    }, []);

    const DATA = [
      {
        id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
        title: 'First Item',
      },
      {
        id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
        title: 'Second Item',
      },
      {
        id: '58694a0f-3da1-471f-bd96-145571e29d72',
        title: 'Third Item',
      },
      {
        id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
        title: 'Second Item',
      },
      {
        id: '58694a0f-3da1-471f-bd96-145571e29d72',
        title: 'Third Item',
      },
      {
        id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
        title: 'Second Item',
      },
      {
        id: '58694a0f-3da1-471f-bd96-145571e29d72',
        title: 'Third Item',
      },
      {
        id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
        title: 'Second Item',
      },
      {
        id: '58694a0f-3da1-471f-bd96-145571e29d72',
        title: 'Third Item',
      },
    ];

    const DATA2 = [
      {
        title: "Today",
        data: ["Pizza","Pizza","Pizza","Pizza", "Burger", "Risotto"]
      },
      {
        title: "Yesterday",
        data: ["French Fries","French Fries","French Fries", "Onion Rings", "Fried Shrimps"]
      },
     
    ];

  const dimensions = Dimensions.get('window');
let miniboxwidth=Math.min(230.5,(Math.max(1200,dimensions.width)-328-32*6)/4);
let maxiboxwidth=Math.min(493,(Math.max(1200,dimensions.width)-328-32*4)/2);

const renderItem = ({ item }) => (
  <MessageSummary title={item.title} />
);

const renderHeader = ({ title }) => (
  <MessageSummaryHeader title={title} />
);

console.log(userStore.screenupdate);
console.log("redraw");
  return (
    <View style={styles.container} >
      <MainFrame dashboard  userStore={userStore} >
      <View style={{flexDirection:"row",marginTop:37,marginLeft:32,marginRight:32}}>

      <View >

<View style={{flexDirection:"row"}}>


<View style={[styles.whitebox,{width:miniboxwidth,height:222,alignItems:"center"}]}>
<Image
source={require('../assets/images/dashboardiconperson.png')}
style={{width:42,height:42,marginTop:24}}/>
<Text style={styles.title1}>{data?.dashboard1}</Text>

<View style={{flexDirection:"row",   marginTop:5,alignItems:"center"}}>
<Image source={require('../assets/images/dashboardarrowup.png')}
style={{width:5,height:10,marginRight:5}}/>
<Text style={styles.titlegreen}>+{data?.dashboard1change}% </Text>
</View>
<Text style={styles.title2}>Patients less than 3<br/>months into recovery</Text>
</View>

<View style={[styles.whitebox,{width:miniboxwidth,height:222,marginLeft:32,alignItems:"center"}]}>
<Image
source={require('../assets/images/dashboardiconeye.png')}
style={{width:42,height:42,marginTop:24}}/>
<Text style={styles.title1}>{data?.dashboard2}</Text>

<View style={{flexDirection:"row",   marginTop:5,alignItems:"center"}}>
<Image source={require('../assets/images/dashboardarrowup.png')}
style={{width:5,height:10,marginRight:5}}/>
<Text style={styles.titlegreen}>+{data?.dashboard2change}% </Text>
</View>
<Text style={styles.title2}>Patients to be<br/>watched closely</Text>
</View>


<View style={[styles.whitebox,{width:miniboxwidth,height:222,marginLeft:32,alignItems:"center"}]}>
<Image
source={require('../assets/images/dashboardiconchart.png')}
style={{width:42,height:42,marginTop:24}}/>
<Text style={styles.title1}>{data?.dashboard3}
<Text style={{position:"absolute",top:91,fontSize:20,marginLeft:8}}>%</Text>

</Text>

<View style={{flexDirection:"row",   marginTop:5,alignItems:"center"}}>
<Image source={require('../assets/images/dashboardarrowup.png')}
style={{width:5,height:10,marginRight:5}}/>
<Text style={styles.titlegreen}>+{data?.dashboard3change}% </Text>
</View>
<Text style={styles.title2}>Avg. compliance rate<br/>this month</Text>
</View>



<View style={[styles.whitebox,{width:miniboxwidth,height:222,marginLeft:32,alignItems:"center"}]}>
<Image
source={require('../assets/images/dashboardiconchat.png')}
style={{width:42,height:42,marginTop:24}}/>
<Text style={styles.title1}>{data?.dashboard4}
<Text style={{position:"absolute",top:91,fontSize:20,marginLeft:8}}>hrs.</Text>
</Text>

<View style={{flexDirection:"row",   marginTop:5,alignItems:"center"}}>
<Image source={require('../assets/images/dashboardarrowup.png')}
style={{width:5,height:10,marginRight:5}}/>
<Text style={styles.titlegreen}>+{data?.dashboard4change}% </Text>
</View>
<Text style={styles.title2}>Avg. response time<br/>this month</Text>
</View>


</View>



<View style={{flexDirection:"row",marginTop:32}}>


<View style={[styles.whitebox,{width:maxiboxwidth,height:420,paddingTop:17}]}>

<View >
<Text style={[styles.title3,{marginLeft:17}]} >Recovery status</Text>
</View>
<Hairline marginTop={26}/>


</View>

<View style={[styles.whitebox,{width:maxiboxwidth,height:420,paddingTop:17,marginLeft:32}]}>
<View>
<Text style={[styles.title3,{marginLeft:17}]}  >Outcome scores</Text>
</View>
<Hairline marginTop={26}/>



</View>


</View>



    </View>
    <View style={[styles.whitebox,{width:328,marginLeft:32,padding:16,height:674,marginBottom:32}]} >

<Text style={styles.title3}>Unread messages</Text>
<View style={{position:"absolute",right:16,top:19}}>

     <TouchableOpacity>
     <View style={{flexDirection:"row",justifyContent:"space-around",alignItems:"center",height:16}}>

        <Text style={{fontFamily:"Lato",fontSize:13,color:"#1F2E99",paddingRight:8}}>All messages</Text>
            <Image
          source={require('../assets/images/dashboardmsgexpandicon.png')}
          style={{width:10,height:6}}/>
          </View>
          </TouchableOpacity>
           
</View>


<SectionList
      sections={DATA2}
      keyExtractor={(item, index) => item + index}
      renderItem={renderItem}
      renderSectionHeader={({ section: { title } }) => (
        renderHeader({title})
      )}
    />

</View>

    </View>



      </MainFrame>
    


    </View>
  );
}));
export default DashboardScreen;

const styles = StyleSheet.create({

  titlegreen:{
    fontFamily:"LatoBold",
    fontSize:13,
    color:"#6DD803",
 

  },

  titlered:{
    fontFamily:"LatoBold",
    fontSize:13,
    color:"#6DD803",
    marginTop:5

  },
  title1:{
fontFamily:"LatoBold",
fontSize:42,
color:"#1F2E99",
marginTop:17

  },
  whitebox:{

    backgroundColor:"#ffffff",borderRadius:8,
    shadowColor: "#818181",
    shadowOffset: {
        width: 0,
        height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,

    elevation: 5,
},
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
   
    height:"100%"
  
  },
  
  title2: {
    fontSize: 13,
    fontFamily: 'Lato',
color:"#616368",
top:11,
textAlign:"center",
marginTop:11,
maxWidth:147,
  }, 
  title3: {
    fontSize: 18,
    fontFamily: 'LatoBold',
color:"#1F2E99",

  }, 

  title4: {
    fontSize: 11,
    fontFamily: 'Lato',
color:"#1F2E99",

  }, 
  title5: {
    fontSize: 11,
    fontFamily: 'Lato',
color:"#798599",

  }, 
  title6: {
    fontSize: 13,
    fontFamily: 'Lato',
color:"#B4B7CC",

  },   title7: {
    fontSize: 15,
    fontFamily: 'LatoBold',
color:"#000000",

  }, 
});
