import * as React from 'react';
import {Image, View,Text ,StyleSheet, TouchableOpacity} from 'react-native';

import { CarePlan,CarePlanWeekSelector } from '../components/CarePlan';
import { MainFrame } from '../components/MainFrame';
import { inject, observer } from 'mobx-react';



const TempScreen=inject("userStore")(observer(({userStore,...props})=> {
    const [week, setWeek] = React.useState(1);
    function onPrev(){setWeek(Math.max(1,week-1))};
    function onNext(){setWeek(Math.min(60,week+1))};

  return (
    <View style={styles.container}>
            <MainFrame patientlist style={{}}>
        {userStore.sessiontoken}
        
      </MainFrame>
    


    </View>
  );
}))
export default TempScreen;

const styles = StyleSheet.create({

  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
  
  },
  title1: {
    fontSize: 32,
    fontFamily: 'MontserratSemiBold',
color:"#1F2E99",
marginTop:"26%"

  },
  title2: {
    width:"84%",
    fontSize: 20,
    fontFamily: 'Lato',
color:"#1F2E99",
position:"absolute",
top:"55%"
  }, 
   title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  
});
