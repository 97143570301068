import * as React from 'react';
import { Text, TextProps,View,StyleSheet,ImageBackground ,Dimensions,Image,TouchableOpacity} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

//export function OrangeButton(props) {
    export const HeaderMin = (prop) => {
        const insets = useSafeAreaInsets();

        const dimensions = Dimensions.get('window');
const bottomdistance=dimensions.height/25+insets.bottom;
    return  <View style={{backgroundColor:"#FCF5EF",width:"100%",height:"100%"}}> <View style={{backgroundColor:"#FFFFFF",height:72,width:"100%",alignItems:"center"}}>

        <View style={{width:940}} >
        <Image
source={require('../assets/images/logopostop.png')}
style={{width:97,height:33,marginTop:21}}     />
        </View>

      


    </View>
    <View style={{width:"100%",alignItems:"center"}}>
    <View style={{width:940,height:"100%"}} >
  {prop.children}
        </View></View>
    </View>
    ;
 // return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}

const styles = StyleSheet.create({
    listbutton:{paddingTop:13,alignSelf:"left",alignItems:"center",
      height: 42,     width:350,  borderRadius:15, 
      backgroundColor : "#FE5935",
 
     alignContent:"center",
     
  
      shadowColor: "#818181",
  shadowOffset: {
      width: 0,
      height: 16,
  },
  shadowOpacity: 0.2,
  shadowRadius: 5,
  
  elevation: 5,
  
    },
    videoicon:{marginTop:0,
      marginRight:18,
  width:34,
      height:24,
     
  
    },
    sendicon:{
      marginRight:20,
    width:27,
        height:27, },
        thumbsupicon:{
      marginRight:20,
    width:28,
        height:28, },


        startqa:{
      marginRight:20,
    width:20,
        height:20, },

        cameraicon:{
      marginRight:20,
    width:29,
        height:24, },



    nextarrow:{marginTop:7,
      marginLeft:20,
  width:20,
      height:11, },
      
  
  
  
    buttonshadowexit:{
      shadowColor: "#818181",
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.2,
      shadowRadius: 5,
      
      elevation: 5,
      
      backgroundColor:"transparent",
    
    },
    buttonshadowretake:{
  
      shadowColor: "#818181",
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.2,
      shadowRadius: 5,
      
      elevation: 5,
      backgroundColor:"transparent",
  
    },
    cameraexitbutton:{
      
      width:81,height:37,
    },
    cameraretakebutton:{
     
  
      width:117,height:37,
    },
    titlecamera1: {
     backgroundColor:"transparent",
         fontSize: 20,
       fontFamily: 'Lato',
       color:"#1F2E99",
       marginTop:25
  
    },  
    titlecamera2: {
      position:"relative",
      fontSize: 20, 
       fontFamily: 'LatoBold', 
       color:"#1F2E99",
  
    },
    scrollviewbottom:{
      height:180, flex:1,
      backgroundColor:"transparent",
  
    },
    scrollviewcontainter:{
      marginTop:250,
      backgroundColor:"transparent",
  
  
    },
  
   
    scrollView:{
      
    },
  
    card:{
      width:"10%",
      aspectRatio: 311/274,
      resizeMode: 'contain',
      marginTop:10,
   marginLeft:5
      
    },
    menuicon:{
      width:99,
      height:37,
      position:"absolute",
      top:38,
      left:"7.8%",
      
    },
    imageday:{
      width:93,
      height:37,
      position:"absolute",
      top:38,
      right:"3.8%",
      
    },
    titleday: {
      fontSize: 20,
      color: "#FE7235", 
      fontFamily: 'Questrial', 
      position:"absolute",
      top:45,
      right:"10%",
    },
    image: {
      flex: 1,
      resizeMode:"contain",
      width: '100%',
      height:'100%',
    },
    container: {
      backgroundColor:"#FCF5EF",
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 20,
      color: "#1F2E99", 
      fontFamily: 'Montserrat', 
       
    },
  
    
    separator: {
      marginVertical: 30,
      height: 1,
     
      width: '80%',
    },
  });
  