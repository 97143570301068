import * as React from 'react';
import { Text, TextProps,View,StyleSheet,ImageBackground ,Dimensions,Image,TouchableOpacity} from 'react-native';
import { A } from '@expo/html-elements';
import { PatientMenuButton } from './PatientMenuButton';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

//export function OrangeButton(props) {
    export const PatientMenu = (props) => {
        
    return      ( 
       <View>
<PatientMenuButton   patientid={props?.patientid} title="Patient overview"  link="/patient"  selected={props.selected==1&&1} />
<PatientMenuButton  patientid={props?.patientid} title="Wound images" link="/patientmedia"  selected={props.selected==2&&1} />
<PatientMenuButton  patientid={props?.patientid} title="PROMS Data"  link="/patientproms"  selected={props.selected==3&&1} />
<PatientMenuButton  patientid={props?.patientid} title="PREMS Data"  link="/patientprems" selected={props.selected==4&&1} />
<PatientMenuButton  patientid={props?.patientid} title="General questions"  link="/patientgeneralquestions" selected={props.selected==5&&1} />
<PatientMenuButton  patientid={props?.patientid} title="Care plan" link="/patientcareplan" selected={props.selected==6&&1} />
<PatientMenuButton  patientid={props?.patientid} title="Messages" link="/patientmessages"  badge={1} selected={props.selected==7&&1} />
<PatientMenuButton  patientid={props?.patientid} title="Diary"   link="/patientdiary" selected={props.selected==8&&1} />
</View>
        );
    }
const styles = StyleSheet.create({
    listbutton:{paddingTop:13,alignItems:"center",
      height: 42,     width:350,  borderRadius:15, 
      backgroundColor : "#1F2E99",
 
     alignContent:"center",
     
  
      shadowColor: "#818181",
  shadowOffset: {
      width: 0,
      height: 16,
  },
  shadowOpacity: 0.2,
  shadowRadius: 5,
  
  elevation: 5,
  
    },
    videoicon:{marginTop:0,
      marginRight:18,
  width:34,
      height:24,
     
  
    },
    sendicon:{
      marginRight:20,
    width:27,
        height:27, },


        inviteusericon:{
      marginRight:20,
    width:16.666,
        height:14, },


        startqa:{
      marginRight:20,
    width:20,
        height:20, },

        cameraicon:{
      marginRight:20,
    width:29,
        height:24, },



    nextarrow:{marginTop:7,
      marginLeft:20,
  width:20,
      height:11, },
      
  
  
  
    buttonshadowexit:{
      shadowColor: "#818181",
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.2,
      shadowRadius: 5,
      
      elevation: 5,
      
      backgroundColor:"transparent",
    
    },
    buttonshadowretake:{
  
      shadowColor: "#818181",
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.2,
      shadowRadius: 5,
      
      elevation: 5,
      backgroundColor:"transparent",
  
    },
    cameraexitbutton:{
      
      width:81,height:37,
    },
    cameraretakebutton:{
     
  
      width:117,height:37,
    },
    titlecamera1: {
     backgroundColor:"transparent",
         fontSize: 20,
       fontFamily: 'Lato',
       color:"#1F2E99",
       marginTop:25
  
    },  
    titlecamera2: {
      position:"relative",
      fontSize: 20, 
       fontFamily: 'LatoBold', 
       color:"#1F2E99",
  
    },
    scrollviewbottom:{
      height:180, flex:1,
      backgroundColor:"transparent",
  
    },
    scrollviewcontainter:{
      marginTop:250,
      backgroundColor:"transparent",
  
  
    },
  
   
    scrollView:{
      
    },
  
    card:{
      width:"10%",
      aspectRatio: 311/274,
      resizeMode: 'contain',
      marginTop:10,
   marginLeft:5
      
    },
    menuicon:{
      width:99,
      height:37,
      position:"absolute",
      top:38,
      left:"7.8%",
      
    },
    imageday:{
      width:93,
      height:37,
      position:"absolute",
      top:38,
      right:"3.8%",
      
    },
    titleday: {
      fontSize: 20,
      color: "#FE7235", 
      fontFamily: 'Questrial', 
      position:"absolute",
      top:45,
      right:"10%",
    },
    image: {
      flex: 1,
      resizeMode:"contain",
      width: '100%',
      height:'100%',
    },
    container: {
      backgroundColor:"#FCF5EF",
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 20,
      color: "#1F2E99", 
      fontFamily: 'Montserrat', 
       
    },
  
    
    separator: {
      marginVertical: 30,
      height: 1,
     
      width: '80%',
    },
  });
  