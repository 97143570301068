import React,{useEffect} from "react";
import {Text, View, StyleSheet } from 'react-native';
import Constants from 'expo-constants';


  export const PasswordStrengthMeter = (props) => {

  const [passwordStrenghtColor, setPasswordStrenghtColor] = React.useState("#ccdd00");
  const [passwordstrengthLabel, setPasswordstrengthLabel] = React.useState('');
  const [passwordstrength, setPasswordstrength] = React.useState(0);


  const colors= ['#ddd', '#ef4836', '#f6b44d', '#66Ff00', '#25c281']
const strengthLabels = [' ',  'Weak', 'Normal', 'Good', 'Strong'];

const [passwordstrenght, setpasswordstrenght] = React.useState(0);



function scorePassword(pass) {
    var score = 0;
    if (!pass)
        return score;

    // award every unique letter until 5 repetitions
    var letters = new Object();
    for (var i=0; i<pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 6.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    }

    var variationCount = 0;
    for (var check in variations) {
        variationCount += (variations[check] == true) ? 1 : 0;
    }
    score += (variationCount - 1) * 13;

    return parseInt(Math.min(100,Math.max(0,score-10)));
}
function changePw(text){

//pwstrenght=(384/100*(Math.min(30,Math.round(text.length/3)*10)     ));
let pwstrenght=scorePassword(text);

setpasswordstrenght(Math.min(350,350/100*pwstrenght*1.1));
    setPasswordStrenghtColor(colors[Math.min(4,Math.floor(pwstrenght/20))]);
    props.setStrenght(Math.min(4,Math.floor(pwstrenght/20)));
    setPasswordstrengthLabel(strengthLabels[Math.min(4,Math.floor(pwstrenght/20))]);



}

useEffect(() => {
    changePw(props.pw);
  }, [props]);


  return ( <View style={[{flexDirection:"row",alignItems:"center"},props.style]}>

     

  <View style={[{height:4,width:350,backgroundColor:"#C4C4C4"}]}>


<View    style={{transitionDuration: "200ms",height:4,width:passwordstrenght,backgroundColor:passwordStrenghtColor}}  />

<View style={[styles.whitebox,{left:84}]}></View>
<View style={[styles.whitebox,{left:172,top:-8}]}></View>
<View style={[styles.whitebox,{left:260,top:-12}]}></View>
<View style={[styles.whitebox,{left:348,top:-16}]}></View>
</View>
    <Text style={{transitionDuration: "200ms",fontFamily:"Lato",marginLeft:8,fontSize:13,color:passwordStrenghtColor}}>{passwordstrengthLabel}</Text></View>

  );
}

const styles = StyleSheet.create({
  whitebox:{
    backgroundColor:"#ffffff",
width:4,
height:4,

    top: -4
  },

  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: Constants.statusBarHeight,
    backgroundColor: '#ecf0f1',
    padding: 8,
  },
  
});
