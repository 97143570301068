import * as React from 'react';
import { Text,View,StyleSheet ,Dimensions,Image,TouchableOpacity} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

//export function OrangeButton(props) {
    export const BlueButton = (props) => {
        const insets = useSafeAreaInsets();

        const dimensions = Dimensions.get('window');
const bottomdistance=dimensions.height/25+insets.bottom;
    return     <TouchableOpacity 
    

    style ={[styles.listbutton,props.style]} 
    onPress={props.onPress}  >
    <View style={{flex: 1, flexDirection: 'row',backgroundColor:"transparent"}}>
 

    {props?.invitenewuser&&<Image
source={require('../assets/images/inviteusericon.png')}
style={styles.invitenewuser}     />}
    <Text style={{color:"#FFFFFF",fontFamily:"Questrial",fontSize:16 }}>{props.title}</Text>



</View>
   </TouchableOpacity>  
    ;
 // return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}

const styles = StyleSheet.create({
    listbutton:{paddingTop:13,alignItems:"center",
      height: 42,     width:350,  borderRadius:15, 
      backgroundColor : "#1F2E99",
 
     alignContent:"center",
     
  
    
  
    },
    invitenewuser:{
      marginRight:18,
  width:14.67,
      height:12,
     
  
    },
    sendicon:{
      marginRight:20,
    width:27,
        height:27, },


        inviteusericon:{
      marginRight:20,
    width:16.666,
        height:14, },


        startqa:{
      marginRight:20,
    width:20,
        height:20, },

        cameraicon:{
      marginRight:20,
    width:29,
        height:24, },



    nextarrow:{marginTop:7,
      marginLeft:20,
  width:20,
      height:11, },
      
  
  
  
    buttonshadowexit:{
      shadowColor: "#818181",
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.2,
      shadowRadius: 5,
      
      elevation: 5,
      
      backgroundColor:"transparent",
    
    },
    buttonshadowretake:{
  
      shadowColor: "#818181",
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.2,
      shadowRadius: 5,
      
      elevation: 5,
      backgroundColor:"transparent",
  
    },
    cameraexitbutton:{
      
      width:81,height:37,
    },
    cameraretakebutton:{
     
  
      width:117,height:37,
    },
    titlecamera1: {
     backgroundColor:"transparent",
         fontSize: 20,
       fontFamily: 'Lato',
       color:"#1F2E99",
       marginTop:25
  
    },  
    titlecamera2: {
      position:"relative",
      fontSize: 20, 
       fontFamily: 'LatoBold', 
       color:"#1F2E99",
  
    },
    scrollviewbottom:{
      height:180, flex:1,
      backgroundColor:"transparent",
  
    },
    scrollviewcontainter:{
      marginTop:250,
      backgroundColor:"transparent",
  
  
    },
  
   
    scrollView:{
      
    },
  
    card:{
      width:"10%",
      aspectRatio: 311/274,
      resizeMode: 'contain',
      marginTop:10,
   marginLeft:5
      
    },
    menuicon:{
      width:99,
      height:37,
      position:"absolute",
      top:38,
      left:"7.8%",
      
    },
    imageday:{
      width:93,
      height:37,
      position:"absolute",
      top:38,
      right:"3.8%",
      
    },
    titleday: {
      fontSize: 20,
      color: "#FE7235", 
      fontFamily: 'Questrial', 
      position:"absolute",
      top:45,
      right:"10%",
    },
    image: {
      flex: 1,
      resizeMode:"contain",
      width: '100%',
      height:'100%',
    },
    container: {
      backgroundColor:"#FCF5EF",
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 20,
      color: "#1F2E99", 
      fontFamily: 'Montserrat', 
       
    },
  
    
    separator: {
      marginVertical: 30,
      height: 1,
     
      width: '80%',
    },
  });
  