import * as React from 'react';
import {Alert,TextInput,Dimensions, View,Text ,StyleSheet,Image,TouchableOpacity} from 'react-native';

import { OrangeButton } from '../../components/OrangeButton';
import validate from '../validate_wrapper'
import SplitFrame from '../../components/SplitFrame';

import { A } from '@expo/html-elements';


export default function RegisterScreen({navigation}) {

    const [email, setEmail] = React.useState('');
    const [errorText, setErrorText] = React.useState('');
    const [temppassword, setTemppassword] = React.useState('');
    const [secure, setSecure] = React.useState(true);



    const dimensions = Dimensions.get('window');


    function inviteagain(){
      fetch(global.apiurl+'/clinicians/inviteagain', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
        
        })
      })
        .then(res => {
          if (res.status !== 200 && res.status !== 201) {
            console.log('Error!');
            setErrorText("Can not confirm invitation.");

            throw new Error('Could not authenticate you!2');
          }
          return res.json();
          
        })
        .then(resData => {
          console.log(resData);
          if (resData.result){
          setErrorText("Mail sent, pleace check your mail box.");

      } else {
        
        setErrorText("Unable to verify,\nPlease check the your email.");

     
        }
         
         
        })
        .catch(err => {
          console.log(err);
          setErrorText("Unable to verify,\nPlease check your connection.");

        });
    }

    function  validateFormInviteAgain(){
      setErrorText("");
      const emailError = validate('email', email);
      if (!emailError) {
        inviteagain();
        } else {
        //  alert("\n"+(emailError?"\n"+emailError+"\n":"")+(passwordError?"\n"+passwordError+"\n":"")+"\n");    
          setErrorText((emailError?"\n"+emailError+"\n":""));    
          
        }
    }

    function validateForm(){
      setErrorText("");
        const passwordError = validate('password', temppassword);
        const emailError = validate('email', email);
        console.log("xxx");
        
       
      
          if (!passwordError && !emailError) {
          checkinvitationcode();
          } else {
          //  alert("\n"+(emailError?"\n"+emailError+"\n":"")+(passwordError?"\n"+passwordError+"\n":"")+"\n");    
            setErrorText((emailError?"\n"+emailError+"\n":"")+(passwordError?"\n"+passwordError+"\n":""));    
            
          }
      }


      function checkinvitationcode() {
        fetch(global.apiurl+'/clinicians/checkinvite', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            temppassword: temppassword,
          
          })
        })
          .then(res => {
            if (res.status === 422) {
              setErrorText("Can not confirm invitation.");
              throw new Error('Validation failed.');

            }
            if (res.status !== 200 && res.status !== 201) {
              console.log('Error!');
              setErrorText("Can not confirm invitation.");

              throw new Error('Could not authenticate you!2');
            }
            return res.json();
          })
          .then(resData => {
            console.log(resData);
           if (resData.result){
          //  navigation.push('RegisterSetPasswordScreen',{gsmcountrycode:gsmcountrycode,gsm:gsm,invitationcode:invitationcode});
          navigation.push('RegisterSetPasswordScreen',{email:email,temppassword:temppassword});


        } else {
          setErrorText("Unable to verify,\nPlease check the your invitation details.");

       
          }
           
           
          })
          .catch(err => {
            console.log(err);
          /*  this.setState({
              isAuth: false,
              authLoading: false,
              error: err
            });*/
          });
      };
    


  return (
    <SplitFrame>
    <View style={{width:350,flexShrink:0.5,backgroundColor:"#ffffff"}}>
    <Image
source={require('../../assets/images/logopostop.png')}
style={{width:178,height:61,marginTop:93}}     />
    <Text style={styles.title1}>Lets get started by creating
your account.</Text>

<Text style={styles.title2}>Insert your temporary password you have received via email.</Text>
<View style={{flex: 1, flexDirection: 'row',marginTop:8}}>
  <Text style={styles.title3}>Didn’t receive the email? </Text>

  <TouchableOpacity   onPress={() => validateFormInviteAgain()} >

  <Text style={styles.title4}>Send it again</Text>
  </TouchableOpacity>
  </View>
  

  <Text style={styles.title5}>
Email address
  </Text>
<View  style={styles.shadowinput} >
  

  <TextInput
      style={styles.phoneinput}  keyboardType="number-pad"
      onChangeText={text => setEmail((text.replace(' ', '')))}
      value={email}
      returnKeyType='done'
      
    />

    </View>
    <Text style={styles.title6}>
    This email address will be used for signing in to your Post Op account.  </Text>

  <Text style={styles.title5}>
  Temporary password
  </Text>
  <View  style={styles.shadowinput} >
  
  
    <TextInput returnKeyType='done'
        style={styles.passwordinput} keyboardType="email-address"
        secureTextEntry={secure} 
        onChangeText={(text) => setTemppassword (text.trim())}
        value={temppassword}
       
      />

      

<TouchableOpacity onPress={() => setSecure(!secure)}>


      <Image  
       source={require('../../assets/images/passwordshow.png')}
       style={styles.passwordshow}     />
       </TouchableOpacity>

      </View>
      <View style={{justifyContent:"flex-end  ",position:"absolute",top:588,height:60}}>
<Text style={{color:"#FF6D6D"}}>{errorText}</Text></View>

<OrangeButton onPress={() => validateForm()} title="Create Account"  on style={{marginTop:93}} />


<View style={{flexDirection:"row",marginBottom:118,marginTop:16,width:350,justifyContent:"center"}}>
<Text style={{fontFamily:"Lato",fontSize:15,color:"#000000",marginLeft:30}}>

Have an account?</Text>
<A href="/login"><Text style={{fontFamily:"Lato",marginLeft:4,fontSize:15,color:"#0009EC",   textDecorationLine: "underline"}}>
Login
</Text></A>

      </View>
      </View>

      </SplitFrame>

  );
}

const styles = StyleSheet.create({
  

  containersplit: {
    flex: 1,
    flexDirection:"row",
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  
  },
  phoneinput:{
    flex:1,outlineWidth:0,
    color:"#1F2E99",fontFamily:"Lato",fontSize:15,
    marginRight:10,
    marginLeft:20,
  },
  shadowinput:{
        
    marginTop:15,
    height:48,
   flexDirection: 'row',borderRadius:15,
    shadowColor: "#818181",
    backgroundColor:"#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    
    elevation: 5,

  },
  title1: {
    fontSize: 32,
    fontFamily: 'LatoBold',
color:"#1F2E99",
marginTop:32,
width:386

  },
  title2: {
      marginTop:18,
    fontSize: 15,
    fontFamily: 'Lato',
color:"#000000",

  }, 
   title3: {
    fontSize: 11,
    lineHeight: 14,
    color:"#000000",
    fontFamily: 'Lato',

  },
  title4:{
    fontSize: 11,
    lineHeight: 14,
    color:"#0009EC",
    fontFamily: 'Lato',

  },
  title5:{
      marginTop:32,
    fontSize: 13,
    color:"#798599",
    fontFamily: 'Lato',

  }, title6:{
      marginTop:8,
    fontSize: 11,
    color:"#798599",
    fontFamily: 'Lato',

  },
  passwordshow: {
    width: 24,
    height:15,
    position:"relative",
    top:20,
    right:15,
  },
 
  passwordinput:{
    width:"100%",
    flex:1,outlineWidth:0,
        color:"#1F2E99",fontFamily:"Lato",fontSize:15,
        marginRight:22,
        marginLeft:20,
    
      },
  
});
