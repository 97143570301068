import * as React from 'react';
import { View,Text ,StyleSheet} from 'react-native';

import { OrangeButton } from '../components/OrangeButton';
import { HeaderMin } from '../components/HeaderMin';


export default function TempScreen() {
  return (
    <View style={styles.container}>
      <HeaderMin>

      <Text style={styles.title1}>Temp Screen</Text>
<Text style={styles.title2}>Temp Screen</Text>
       <OrangeButton    title="Temp" width={"84%"}   />

        
      </HeaderMin>
    


    </View>
  );
}

const styles = StyleSheet.create({

  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
  
  },
  title1: {
    fontSize: 32,
    fontFamily: 'MontserratSemiBold',
color:"#1F2E99",
marginTop:"26%"

  },
  title2: {
    width:"84%",
    fontSize: 20,
    fontFamily: 'Lato',
color:"#1F2E99",
position:"absolute",
top:"55%"
  }, 
   title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  
});
