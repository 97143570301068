import * as React from 'react';
import {Alert,TextInput,Dimensions, View,Text ,StyleSheet,Image,TouchableOpacity} from 'react-native';
import { A } from '@expo/html-elements';

import { OrangeButton } from '../../components/OrangeButton';
import { PasswordStrengthMeter } from '../../components/PasswordStrengthMeter';
import validate from '../validate_wrapper'
import { inject, observer } from 'mobx-react';
import SplitFrame from '../../components/SplitFrame';


const Temp=inject("userStore")(observer(({userStore,navigation})=> {
  const [formState, setFormState] = React.useState(1);

  

    const [email, setEmail] = React.useState('');
    const [passwordstrength, setPasswordstrength] = React.useState(0);
    const [errorText, setErrorText] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const [secure, setSecure] = React.useState(true);
    const [secure2, setSecure2] = React.useState(true);
    const [resetpasswordcode,setResetpasswordcode] = React.useState("");


    const dimensions = Dimensions.get('window');
   

    function resetpassword() {
      console.log("reseting password");
  
        fetch(global.apiurl+'/clinicians/resetpassword', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            newpassword:password,
            resetpasswordcode:resetpasswordcode,

          })
        })
          .then(res => {
             
            if (res.status === 422) {
  
              throw new Error('Request failed.');
            }
            if (res.status !== 200 && res.status !== 201) {
              console.log('Error!');
              throw new Error('Could not authenticate you!');
            }
            return res.json();
          })
          .then(resData => {
            console.log(resData);
           if (resData.result){
             console.log("DONE");
  setFormState(4);
            } else {
    
            Alert.alert("Unable to reset password","Please check the form");
          }
           
          
          })
          .catch(err => {
              Alert.alert("Unable to reset password","Please check the form");
  
            console.log(err);
         
          });
      };


      function checkpasswordresetcode() {
        fetch(global.apiurl+'/clinicians/checkpasswordresetcode', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            resetpasswordcode: resetpasswordcode,
          })
        })
          .then(res => {
            if (res.status === 422) {
              setErrorText("Code validation failed.");
              throw new Error('Validation failed.');

            }
            if (res.status !== 200 && res.status !== 201) {
              console.log('Error!');
              setErrorText("Code validation failed.");

              throw new Error('Could not authenticate you!2');
            }
            return res.json();
          })
          .then(resData => {
            console.log(resData);
           if (resData.result){
              setFormState(3);
          } else {
            setErrorText("Code validation failed.");

         //   Alert.alert("Unable to verify","Please check the your invitation details.");
          }
           
            /*this.setState({
              isAuth: true,
              token: resData.token,
              authLoading: false,
              userId: resData.userId
            });
            localStorage.setItem('token', resData.token);
            localStorage.setItem('userId', resData.userId);
            const remainingMilliseconds = 60 * 60 * 1000;
            const expiryDate = new Date(
              new Date().getTime() + remainingMilliseconds
            );
            localStorage.setItem('expiryDate', expiryDate.toISOString());
            this.setAutoLogout(remainingMilliseconds);*/
          })
          .catch(err => {
            console.log(err);
          /*  this.setState({
              isAuth: false,
              authLoading: false,
              error: err
            });*/
          });
      };

    function sendresetpasswordscode() {
      console.log("sending reset password mail");
  
        fetch(global.apiurl+'/clinicians/sendresetpasswordscode', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          email:email
          })
        })
          .then(res => {
             
            if (res.status === 422) {
  
              throw new Error('Request failed.');
            }
            if (res.status !== 200 && res.status !== 201) {
              console.log('Error!');
              setErrorText("Unable to find user.");
              throw new Error('Could not authenticate you!');

            }
            return res.json();
          })
          .then(resData => {
            console.log(resData);
           if (resData.result){
             console.log("DONE");
             setFormState(2);

            } else {
    setErrorText("Unable to find user.")
           // Alert.alert("Unable to find user","Please check the form");
          }
           
         
          })
          .catch(err => {    setErrorText("Unable to find user.")

//              Alert.alert("Unable to find user","Please check the form");
  
            console.log(err);
          
          });
      };
      

    function validateForm(){
      setErrorText("");
        const passwordError = validate('password', password);
        const emailError = validate('email', email);
        const resetpasswordcodeError = validate('resetpasswordcode', resetpasswordcode);


        let password2Error=null;
  
        if (password!=password2){password2Error="Password's don't match."};
        let passwordStrengthError = null;

        if ((password.length>4) && (passwordstrength<3)){passwordStrengthError="Password is weak."};
     
        console.log("xxx");
        
       

        switch(formState){
          case 1:
            if ( !emailError) {
              sendresetpasswordscode();

            } else {
              setErrorText(emailError);    

            }
            break;
          
            case 2:
              if (!(resetpasswordcodeError) ) {
                
                checkpasswordresetcode();
            } else {
              setErrorText(resetpasswordcodeError);    

            }
            break; 
              case 3:
              if (!(passwordError||password2Error) ) {
                resetpassword();


            } else {
              setErrorText((passwordStrengthError?"\n"+passwordStrengthError+"\n":"")+(passwordError?"\n"+passwordError+"\n":"")+(password2Error?"\n"+password2Error+"\n":""));    

            }
            break;
          }
      
      
      }


      function checkinvitationcode() {
        fetch(global.apiurl+'/clinicians/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            password: password,
          
          })
        })
          .then(res => {
            if (res.status === 422) {
              setErrorText("Unable to verify,\nPlease check the your login details.");
              throw new Error('Validation failed.');

            }
            if (res.status !== 200 && res.status !== 201) {
              console.log('Error!');
              setErrorText("Unable to verify,\nPlease check the your login details.");

              throw new Error('Could not authenticate you!2');
            }
            return res.json();
          })
          .then(resData => {
            console.log(resData);
           if (resData.result){
            userStore.setLoggedin(resData.token,resData.photourl);


        } else {
          setErrorText("Unable to verify,\nPlease check the your login details.");

        //    alert("Please check the your invitation details.");

         //   Alert.alert("Unable to verify","Please check the your invitation details.");
          }
           
            
          })
          .catch(err => {
            console.log(err);
          /*  this.setState({
              isAuth: false,
              authLoading: false,
              error: err
            });*/
          });
      };
    


  return (    <View style={styles.container}>

<View style={{ zIndex:100, alignItems: "center", justifyContent: "center",position:"absolute",top:32,left:24}}>
                <A href="/login">
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Image
                            source={require('../../assets/images/backicon.png')}
                            style={{ width: 12.5, height: 8, marginRight: 11.5 }} />
                        <Text style={{fontFamily:"Lato",fontSize:13,color:"#1F2E99"}}>Back </Text></View>
                </A>
            </View>
<SplitFrame>

    <View style={{width:350,flexShrink:0,backgroundColor:"#ffffff"}}>
    <Image
source={require('../../assets/images/logopostop.png')}
style={{width:178,height:61,marginTop:168}}     />
    
    {formState==1&&<View>
    <Text style={[styles.title1,{width:400}]}>Password reset</Text>
    <Text style={styles.title2}>To reset your password, enter the email
    address that you use to sign in to Post Op.</Text>
  <Text style={[styles.title5,{marginTop:32}]}>
Email address
  </Text>
<View  style={styles.shadowinput} >
  
  <TextInput
      style={styles.phoneinput}  keyboardType="number-pad"
      onChangeText={text => setEmail((text.replace(' ', '')))}
      value={email}
      returnKeyType='done' />

    </View>
    </View>}
    
      {formState==2&&<View>
        
    <Text style={styles.title1}>Enter verification code</Text>
    <Text style={styles.title2}>We sent you a verification code to the email address connected to this account.
    {"\n"}{"\n"}
Insert it below to reset your password.</Text>
  <Text style={[styles.title5,{marginTop:32}]}>
Verification code
  </Text>


<View  style={styles.shadowinput} >
  
  <TextInput
      style={styles.phoneinput}  keyboardType="number-pad"
      onChangeText={text => setResetpasswordcode((text.replace(' ', '')))}
      value={resetpasswordcode}
      returnKeyType='done' />

    </View>
    <View style={{flexDirection:"row",marginTop:16,width:350,justifyContent:"left"}}>

<Text style={{fontFamily:"Lato",fontSize:11,lineHeight:14,color:"#000000"}}>

Didn’t receive the email? </Text>
<TouchableOpacity onPress={sendresetpasswordscode}><Text style={{fontFamily:"Lato",marginLeft:4,fontSize:11,lineHeight:14,color:"#0009EC",   textDecorationLine: "underline"}}>
Send it again
</Text></TouchableOpacity>

      </View>
      <Text style={{fontFamily:"Lato",fontSize:11,lineHeight:14,color:"#000000"}}>
    Don’t forget to check your spam box.</Text>

    </View>}
    {formState==3&&<View>
      <Text style={styles.title1}>Set your new password</Text>
      <Text style={styles.title5}>
      Set new password 
  </Text>

  <View  style={styles.shadowinput} >
  
  
    <TextInput returnKeyType='done'
        style={styles.passwordinput} keyboardType="email-address"
        secureTextEntry={secure} 
        onChangeText={(text) => setPassword (text.trim())}
        value={password}
       
      />

      

<TouchableOpacity onPress={() => setSecure(!secure)}>


      <Image  
       source={require('../../assets/images/passwordshow.png')}
       style={styles.passwordshow}     />
       </TouchableOpacity>

      </View>
      
      
        <PasswordStrengthMeter style={{marginTop:11}} pw={password}  setStrenght={x=>{setPasswordstrength(x);console.log("pw strength:"+x);}}  />
   

      <Text style={styles.title5}>
      Repeat new password
  </Text>
  <View  style={styles.shadowinput} >
  
  
    <TextInput returnKeyType='done'
        style={styles.passwordinput} keyboardType="email-address"
        secureTextEntry={secure2} 
        onChangeText={(text) => setPassword2 (text.trim())}
        value={password2}
       
      />

      

<TouchableOpacity onPress={() => setSecure2(!secure2)}>


      <Image  
       source={require('../../assets/images/passwordshow.png')}
       style={styles.passwordshow}     />
       </TouchableOpacity>

      </View>

    
  </View>}
  {formState==4&&<View>
    
    <Text style={styles.title1}>New password was{"\n"}successfully created.</Text>

    </View>}
      <View style={{justifyContent:"flex-end  ",}}>


    

<Text style={{color:"#FF6D6D"}}>{errorText}</Text></View>

{formState==1&&<OrangeButton onPress={() => validateForm()} title="Get Password Reset Link"  on style={{marginTop:93}} />}
{formState==2&&<OrangeButton onPress={() => validateForm()} title="Verify Code"  on style={{marginTop:93}} />}
{formState==3&&<OrangeButton onPress={() => validateForm()} title="Set new password"  on style={{marginTop:86}} />}
{formState==4&&<OrangeButton onPress={() => navigation.push('LoginScreen')} title="Go to login"  on style={{marginTop:86}} />}



      </View>

      </SplitFrame>

    </View>
  );
}))

export default Temp;


const styles = StyleSheet.create({
  
container:{
  flex: 1,

},
  containersplit: {
    flex: 1,
    flexDirection:"row",
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  
  },
  phoneinput:{
    flex:1,outlineWidth:0,
    color:"#1F2E99",fontFamily:"Lato",fontSize:15,
    marginRight:10,
    marginLeft:20,
  },
  shadowinput:{
        
    marginTop:15,
    height:48,
   flexDirection: 'row',borderRadius:15,
    shadowColor: "#818181",
    backgroundColor:"#FFFFFF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    
    elevation: 5,

  },
  title1: {
    fontSize: 32,
    fontFamily: 'LatoBold',
color:"#1F2E99",
marginTop:39.6,


  },
  title2: {
      marginTop:18,
    fontSize: 18,
    fontFamily: 'Lato',
color:"#000000",
width:349

  }, 
   title3: {
    fontSize: 11,
    lineHeight: 14,
    color:"#000000",
    fontFamily: 'Lato',

  },
  title4:{
    fontSize: 13,
    lineHeight: 14,
    color:"#0009EC",
    fontFamily: 'Lato',
    textDecorationLine: "underline"
  },
  title5:{
      marginTop:32,
    fontSize: 13,
    color:"#798599",
    fontFamily: 'Lato',

  }, title6:{
      marginTop:8,
    fontSize: 11,
    color:"#798599",
    fontFamily: 'Lato',

  },
  passwordshow: {
    width: 24,
    height:15,
    position:"relative",
    top:20,
    right:15,
  },
 
  passwordinput:{
    width:"100%",
    flex:1,outlineWidth:0,
        color:"#1F2E99",fontFamily:"Lato",fontSize:15,
        marginRight:22,
        marginLeft:20,
    
      },
  
});
