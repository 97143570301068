import * as React from 'react';
import {Dimensions, View ,StyleSheet,Image,TouchableOpacity} from 'react-native';




export default function SplitFrame(props) {
    const dimensions = Dimensions.get('window');

 
  return (
    <View style={styles.containersplit}>
      
 <View style={{width:dimensions.width/2-350-100,flexShrink:0.5}}/>

<View style={{minWidth:350,height:"100%",alignItems:"flex-start",backgroundColor:"#ffffff"}}>

 
    <View style={{width:350,marginLeft:10,flexShrink:0.5,backgroundColor:"#ffffff"}}>
   

    {props.children}


      </View>

   
</View>
<View style={{width:197,flexShrink:0.5,backgroundColor:"white"}}>

</View>
<View style={{width:300,backgroundColor:"white",flexShrink:1,flexGrow:1,
}}>
<Image
source={require('../assets/images/bglogin.jpg')}
style={{height:"100%",resizeMode: "cover",flexShrink:1,
}}     />
</View>


    </View>
  );
}

const styles = StyleSheet.create({
  

  containersplit: {
    flex: 1,
    flexDirection:"row",
    backgroundColor: '#fff',
   
  },
  
  
});
