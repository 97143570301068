import * as React from 'react';
import { Text,View,StyleSheet ,Dimensions,Image,TouchableOpacity} from 'react-native';
import { A ,Table,TR,TD,TBody,THead} from '@expo/html-elements';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

//export function OrangeButton(props) {
export const CarePlanWeekSelector=(props)=>{
    return <View style={[{flexDirection:"row",position:"absolute",right:32},props.style]}>
    <Text style={{ color:"#1F2E99",fontFamily:"Lato",fontSize:15}}>Week {props.week} - {props.week+1}</Text>
<TouchableOpacity style={{marginLeft:12}} onPress={props.onPrev} >
<Image style={{width:20,height:20}} source={require('../assets/images/careplanprevbutton.png')}     />
</TouchableOpacity>
<TouchableOpacity style={{marginLeft:14}} onPress={props.onNext}  >
<Image style={{width:20,height:20}} source={require('../assets/images/careplannextbutton.png')}     />
</TouchableOpacity>
   </View>

}


    export const CarePlan = (props) => {
        const insets = useSafeAreaInsets();

        const dimensions = Dimensions.get('window');
const bottomdistance=dimensions.height/25+insets.bottom;

function carePoint(){
    var random_boolean = Math.random() <= 0.5;

return <View style={{width:30}}>{random_boolean&&<View style={{backgroundColor:"#007EF4",width:10,height:10,borderRadius:5}}></View>}</View>
}

function emptyRow(){
    return <View style={{flexDirection:"row",width:866}}>
<View style={{width:141,height:3,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    </View>
<View style={{flexDirection:"row",width:724,height:3,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >

</View> </View>;
}
function renderDay(title){
    return <View style={{width:30}}><Text style={[styles.title1]}>{title}</Text></View>;
}

function renderCarePoints(){
    const output = [];
    for (let i = 1; i < 15; i++) {
        output.push(carePoint());
        }
return output;

}
function renderDays(){
    const output = [];
    for (let i = 1+(props?.week-1)*7; i < 15+(props?.week-1)*7; i++) {
        output.push(renderDay(i));
        }
return output;

}
    return   <View style={[{backgroundColor:"#ffffff",borderColor:"#E8E9ED",borderWidth:1},props.style]}>

        <View style={{flexDirection:"row",width:866,borderColor:"#E8E9ED",borderBottomWidth:1}}>
<View style={{width:141,height:57,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    <Text style={styles.title1}>Recovery day</Text></View>
<View style={{flexDirection:"row",width:724,height:57,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >
{renderDays()}
</View> </View>

<View style={{flexDirection:"row",width:866}}>
<View style={{width:141,height:12,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    </View>
<View style={{flexDirection:"row",width:724,height:12,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >

</View> </View>

<View style={{backgroundColor:"#F9F9F9",flexDirection:"row",width:866}}>
<View style={{width:141,height:47,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    <Text style={styles.title1}>Wound image</Text></View>
<View style={{flexDirection:"row",width:724,height:47,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >
{renderCarePoints()}
</View> </View> 
{emptyRow()}
<View style={{backgroundColor:"#FCF5EF",flexDirection:"row",width:866}}>
<View style={{width:141,height:47,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    <Text style={styles.title1}>SSIS</Text></View>
<View style={{flexDirection:"row",width:724,height:47,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >
{renderCarePoints()}
</View> </View> 
{emptyRow()}


<View style={{backgroundColor:"#F9F9F9",flexDirection:"row",width:866}}>
<View style={{width:141,height:47,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    <Text style={styles.title1}>EQ5D</Text></View>
<View style={{flexDirection:"row",width:724,height:47,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >
{renderCarePoints()}
</View> </View> 
{emptyRow()}
<View style={{backgroundColor:"#FCF5EF",flexDirection:"row",width:866}}>
<View style={{width:141,height:47,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    <Text style={styles.title1}>PREMS</Text></View>
<View style={{flexDirection:"row",width:724,height:47,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >
{renderCarePoints()}
</View> </View> 
{emptyRow()}



<View style={{backgroundColor:"#F9F9F9",flexDirection:"row",width:866}}>
<View style={{width:141,height:47,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    <Text style={styles.title1}>OKS</Text></View>
<View style={{flexDirection:"row",width:724,height:47,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >
{renderCarePoints()}
</View> </View> 
{emptyRow()}
<View style={{backgroundColor:"#FCF5EF",flexDirection:"row",width:866}}>
<View style={{width:141,height:47,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    <Text style={styles.title1}>GAD7</Text></View>
<View style={{flexDirection:"row",width:724,height:47,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >
{renderCarePoints()}
</View> </View> 
{emptyRow()}


<View style={{backgroundColor:"#F9F9F9",flexDirection:"row",width:866}}>
<View style={{width:141,height:47,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    <Text style={styles.title1}>PHQ9</Text></View>
<View style={{flexDirection:"row",width:724,height:47,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >
{renderCarePoints()}
</View> </View> 
{emptyRow()}
<View style={{backgroundColor:"#FCF5EF",flexDirection:"row",width:866}}>
<View style={{width:141,height:47,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    <Text style={styles.title1}>Doc - apt</Text></View>
<View style={{flexDirection:"row",width:724,height:47,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >
{renderCarePoints()}
</View> </View> 
{emptyRow()}
<View style={{backgroundColor:"#F9F9F9",flexDirection:"row",width:866}}>
<View style={{width:141,height:47,borderColor:"#E8E9ED",borderRightWidth:1,alignItems:"center",justifyContent:"center"}}>
    <Text style={styles.title1}>Physio - apt</Text></View>
<View style={{flexDirection:"row",width:724,height:47,borderColor:"#E8E9ED",alignItems:"center",justifyContent:"space-evenly"}} >
{renderCarePoints()}
</View> </View> 


        
    </View>

}

const styles = StyleSheet.create({
    title1:{
        color:"#1F2E99",
        fontFamily:"Lato",
        fontSize:15
    } 
  });
  