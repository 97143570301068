import React, { useState, useEffect } from 'react';

import { View,Text ,StyleSheet,Image,TouchableOpacity,TextInput} from 'react-native';

import UpdateClinician  from '../../components/UpdateClinician';
import { HeaderMin } from '../../components/HeaderMin';
import { inject, observer } from 'mobx-react';


  const RegisterUpdateDetailsScreen=inject("userStore")(observer(({userStore,...props})=> {
 

  return (
    <View style={styles.container}>
      <HeaderMin>

     <UpdateClinician afterregister userstore={userStore}/> 

        
      </HeaderMin>
    


    </View>
  );
}))
export default RegisterUpdateDetailsScreen;

const styles = StyleSheet.create({
 
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
  
  },

  
});
