import React, { useState, useEffect } from 'react';
import {TextInput, FlatList,Image,View,Text ,StyleSheet, TouchableHighlight, TouchableOpacity} from 'react-native';

import { MainFramePatient } from '../components/MainFramePatient';
import { Comment } from '../components/Comment';
import { Hairline } from '../components/Hairline';
import { inject, observer } from 'mobx-react';


const TempScreen=inject("userStore")(observer((props)=> {

    const [ commenttext, setCommenttext] = React.useState("");
    const {patientid} = props.route.params;
    const [data, setData] = React.useState(null);
    const [sendingData, setSendingData] = useState(false);
    
      async function sendComment() {
    if (commenttext.length<3) return;
    if(sendingData) return;
              setSendingData(true);
              console.log("sendComment");
              let tokenparam = 'None';
              
              if (global.token) { tokenparam = 'Token ' + global.token }
              fetch(global.apiurl + '/clinicians/addpatientcomment', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': tokenparam,
                },
                body: JSON.stringify({
                  patientid: patientid,
                  message:commenttext
                  })
              }).then((response) => response.json())
                .then(resData => {
                  setSendingData(false);
    
                  console.log(resData);
                  if (resData.result) {
                    fetchData();
                    setCommenttext("");
                  } else {
                  }
        
                }).catch(err => {
                  setSendingData(false);
                  console.log(err);
               
                });
            }
        useEffect(() => {
            
            fetchData();
          }, []);


          async function fetchData() {
            let tokenparam = 'None';
            
            if (global.token) { tokenparam = 'Token ' + global.token }
            fetch(global.apiurl + '/clinicians/getpatientdiary', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenparam,
              },
              body: JSON.stringify({
                patientid: patientid,
                  
                })
            }).then((response) => response.json())
              .then(resData => {
                console.log(resData);
                if (resData.result) {
                  setData(resData);
                } else {
                }
      
              }).catch(err => {
                console.log(err);
             
              });
          }
          
  
    useEffect(() => {
        
        fetchData();
      }, []);


let flatList;

  return (
    <View style={styles.container}>
        {data&&<MainFramePatient  userStore={props.userStore}  selected={8} patient={data.patient}  patientlist style={{alignItems:"flex-start",flexDirection:"row",width:"100%"}}>
  
<View style={[styles.whitebox,{width:"100%"}]}>
<Text style={styles.title1}>Timeline of notes & updates</Text>

<Hairline marginTop={24} />


<FlatList   

ref={ref =>flatList = ref}
initialNumToRender={1} 
onLayout={() =>flatList.scrollToEnd({animated: false})}

onContentSizeChange={() => flatList.scrollToEnd({animated: false})}

 style={{width:"100%",height:514,overflow:"scroll",borderBottomColor:"#E8E9ED",borderBottomWidth:1,marginBottom:68}}
  data={data.patientcomments}
  renderItem={({ item, index, separators }) => (
  
  

    <Comment photourl={item?.clinician?.photourl} fullname={item?.clinician?.firstname+" "+item.clinician?.lastname} date={item?.createdAt} 
    msg={item.message}
    />
   

  )}
/>

<View style={{position:"absolute",right:0,bottom:0,height:68,alignItems:"center",backgroundColor:"#ffffff",width:"100%",borderRadius:8,}}
>
{commenttext==""&&<Text style={{fontFamily:"Lato",fontSize:15,color:"#959DAD",position:"absolute",left:24,marginTop:25}}>Write a note about the patient</Text>}

<TextInput editable={!sendingData}
  onSubmitEditing={sendComment}

  style={[{fontFamily:"Lato",fontSize:15,color:"#959DAD", textAlign: "left",position:"absolute",left:24,marginRight:24,width:850,marginTop:25  }]} keyboardType="email-address"
  maxLength={1000}
  numberOfLines={1}
  onChangeText={text => setCommenttext(text )}
  value={commenttext}   /> 

<TouchableOpacity style={{position:"absolute",right:13,top:13}} 
onPress={sendComment}
>
        <Image
source={require('../assets/images/sendcommentbutton.png')}
style={{width:42,height:42}}/>
            </TouchableOpacity>
  

</View>

</View>

        
      </MainFramePatient>}
    


    </View>
  );
}))
export default  TempScreen;

const styles = StyleSheet.create({
    whitebox:{

        backgroundColor:"#ffffff",borderRadius:8,
        shadowColor: "#818181",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
    
        elevation: 5,
    },
title1:{
    fontFamily:"LatoBold",
    fontSize:18,
    color:"#1F2E99",
    marginLeft:24,
    marginTop:24
},title2:{
    fontFamily:"Lato",
    fontSize:15,
    color:"#1F2E99"
},
title3:{
    fontFamily:"LatoBold",
    fontSize:18,
    color:"#1F2E99"
},

    container: {
        flex: 1
      },
     
  
});
