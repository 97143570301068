import React, { useState, useEffect } from 'react';
import { Dimensions,Animated,FlatList,SectionList, Image, View, Text, StyleSheet, TouchableHighlight, TouchableOpacity } from 'react-native';
import { MainFramePatient} from '../components/MainFramePatient';
import { WoundHeader,WoundItem,WoundDetails} from '../components/Wounds';
import { SectionGrid } from 'react-native-super-grid';


import { inject, observer } from 'mobx-react';
//import Modal from 'modal-enhanced-react-native-web'
import Modal from 'modal-react-native-web';

//export default function TempScreen({navigation}) {



  const PatientWoundsScreen=inject("userStore")(observer(({userStore,navigation,route,props})=> {
    const {patientid} = route?.params;
    const [data, setData] = React.useState(null);
    const [selectedWound, setSelectedWound] = React.useState(null);
    const [pinFilter, setPinFilter] = React.useState(false);
    const [fullFilter, setFullFilter] = React.useState(false);
    const [topFilter, setTopFilter] = React.useState(false);
    const [bottomFilter, setBottomFilter] = React.useState(false);
 
    const [visible, setVisible] = React.useState(false);
  const dimensions = Dimensions.get('window');
console.log(dimensions);
  let numcol=3;
let    listwidth;



listwidth=(dimensions.width-226);

  const opacity = new Animated.Value(1);

 

 function fadeIn (item) {

  setSelectedWound(item.id);

    setVisible(true);
   
   
     };
     
       function fadeOut () {
         Animated.timing(opacity, {
             toValue: 0,
             duration: 175
           }).start(()=>setVisible(false));
          
       };
       

    const DATA2 = [
        {
          title: "Week 3 of recovery (6 images)",
          data: ["Day 20 / Top","Day 20 / Bottom","Day 18 / Top","Day 18 / Bottom","Day 20 / Top","Day 20 / Bottom","Day 18 / Top","Day 18 / Bottom"]
        },
        {
            title: "Week 2 of recovery (4 images)",
            data: ["Day 20 / Top","Day 20 / Bottom","Day 18 / Top","Day 18 / Bottom","Day 20 / Top","Day 20 / Bottom","Day 18 / Top","Day 18 / Bottom"]
          },
          {
            title: "Week 1 of recovery (4 images)",
            data: ["Day 20 / Top","Day 20 / Bottom","Day 18 / Top","Day 18 / Bottom","Day 20 / Top","Day 20 / Bottom","Day 18 / Top","Day 18 / Bottom"]
          },
           
      ];


      async function fetchData() {
        let tokenparam = 'None';
        
        if (global.token) { tokenparam = 'Token ' + global.token }
        fetch(global.apiurl + '/clinicians/getpatientmedia', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenparam,
          },
          body: JSON.stringify({
              patientid: patientid,
              
            })
        }).then((response) => response.json())
          .then(resData => {
            console.log(resData);
            if (resData.result) {
              setData(resData);
            } else {
            }
  
          }).catch(err => {
            console.log(err);
         
          });
      }

      useEffect(() => {
        
          fetchData();
        }, []);



    const renderItem = ({ item }) => (

        
        <WoundItem item={item} fadeIn={()=>{fadeIn(item)}} />
      );
      
      const renderHeader = ({ title,style }) => (
        <WoundHeader title={title}  style={style}/>
      );


   let   viewabilityConfig = {
        itemVisiblePercentThreshold: 50,
        minimumViewTime: 1,
    };
    console.log(userStore.screenupdate);
  
    let allphotocount=0;
    let pinnedphotocount=0;
    /*
    const [allPhotoCount, setAllPhotoCount] = React.useState(null);
    const [pinnedPhotoCount, setPinnedPhotoCount] = React.useState(null);
    
    */

   function ispinned(item) {
    return item.ispinned == 1;
  }
function getweek(dayno){
  return (1+Math.floor((dayno-1)/7))
}
    function renderList(   pinFilter,fullFilter,topFilter,bottomFilter){
  const output = [];
  let first=true;
  let showitem=true;
  let showitem2=true;

  let currentweek=-1;
      data.patientmedia.forEach(item => {

        showitem=((!pinFilter)||(item.ispinned)) ;
        showitem2=!(topFilter||bottomFilter||fullFilter);
 
     if (fullFilter&&((item.phototypeid==1))) showitem2=true;
     if (topFilter&&((item.phototypeid==2))) showitem2=true;
     if (bottomFilter&&((item.phototypeid==3))) showitem2=true;
        if (showitem&&showitem2)
        {

          if (currentweek!=getweek(item.dayno)){
          currentweek=getweek(item.dayno);
          if (!first) {
          output.push(renderHeader({title:"Week "+currentweek+" of recovery",style:{width:listwidth,marginTop:60 }}));
          } else {
            output.push(renderHeader({title:"Week "+currentweek+" of recovery",style:{width:listwidth,marginTop:24 }}));


          }
        first=false;
      }
      allphotocount++;
      if (item.ispinned) pinnedphotocount++;
      output.push(renderItem({item:item}));
    }

      });
      //output.unshift(<Text>Hello</Text>)
 
      return output;

    }
    return (
        <View style={styles.container}>


{(data)&&<MainFramePatient userStore={userStore}  selected={2} patient={data?.patient}  style={{paddingLeft:0,paddingRight:0,paddingTop:0,flexDirection:"column"}}>

                 <Modal         
  onBackdropPress={() => setVisible(false)}
          animationType="fade"
          transparent={true}
          visible={visible}
          onDismiss={() => {
            fetchData();
          //  alert('Modal has been closed.');
          }}>
        {visible&&<WoundDetails pateientmediaid={selectedWound} visible={visible} removeView={fadeOut}></WoundDetails>}
        </Modal>

               <View style={{paddingLeft:65,paddingRight:79,height:50,width:"100%",backgroundColor:"#ffffff",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
<View style={{flexDirection:"row",alignItems:"center"}}>

<TouchableOpacity onPress={(x)=>{ setPinFilter(false);  }}>

<Text style={[styles.title1,pinFilter&&styles.title2]}>All Photos({data?.patientmedia.length})</Text>
</TouchableOpacity>

<TouchableOpacity style={{marginLeft:33,marginTop:0}} onPress={(x)=>{ setPinFilter(true);  }}>
<View style={{flexDirection:"row",alignItems:"center",height:44}}>
<Image
    source={require('../assets/images/pinicon.png')}
    style={styles.pinicon} />

    <Text style={[styles.title2,{marginLeft:6.67},pinFilter&&styles.title1]}>Pinned Photos({data?.patientmedia.filter(ispinned).length})</Text>
</View>
</TouchableOpacity>



</View>
<View style={{flexDirection:"row",alignItems:"center"}}>
<View style={{flexDirection:"row",alignItems:"center",height:44}}>
<Image
    source={require('../assets/images/photoicon.png')}
    style={styles.photoicon} />

    <Text style={[styles.title3,{    marginLeft:6.67}]}>Show wound</Text>
</View>
<TouchableOpacity style={{marginLeft:33,marginTop:0}} onPress={(x)=>{ setFullFilter(false);setTopFilter(false);setBottomFilter(false);  }}>

<Text style={[styles.title2,{marginLeft:30},(!(fullFilter||topFilter||bottomFilter))&&styles.title1]}>All</Text>
</TouchableOpacity>

<TouchableOpacity style={{marginLeft:33,marginTop:0}} onPress={(x)=>{ setFullFilter(!fullFilter);  }}>
<Text  style={[styles.title2,{marginLeft:32},fullFilter&&styles.title1]}>Full</Text>
</TouchableOpacity>
<TouchableOpacity style={{marginLeft:33,marginTop:0}} onPress={(x)=>{ setTopFilter(!topFilter);  }}>
<Text  style={[styles.title2,{marginLeft:17},topFilter&&styles.title1]}>Top</Text>
</TouchableOpacity>
<TouchableOpacity style={{marginLeft:33,marginTop:0}} onPress={(x)=>{ setBottomFilter(!bottomFilter);  }}>
<Text  style={[styles.title2,{marginLeft:17},bottomFilter&&styles.title1]}>Bottom</Text>
</TouchableOpacity>

</View>
</View>

<View style={{width:listwidth,marginBottom:64,marginLeft:43, paddingRight: 63, flexDirection: "row",flexWrap:"wrap" }}>

{renderList(   pinFilter,fullFilter,topFilter,bottomFilter)}

{(1>2)&&<SectionList  
      sections={DATA2}
      keyExtractor={(item, index) => item + index}


      renderSectionHeader={({ section }) => (
        <>
        <WoundHeader title={section.title} />
            <FlatList
              numColumns={numcol}
              data={section.data}
              key={(numcol)}

              renderItem={renderItem}
style={{marginBottom:60,}}
              showsHorizontalScrollIndicator={false}
            />
          
        </>
      )}

      renderItem={({ item, section }) => {
          return null;
    
      }}
      
   
    />}



</View>
           </MainFramePatient>}
        </View>
    );
}))
export default  PatientWoundsScreen;

const styles = StyleSheet.create({
    whitebox: {
        backgroundColor: "#ffffff", borderRadius: 8,
        shadowColor: "#818181",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 5,
    },
    title1: {
        fontFamily: "LatoBold",
        fontSize: 15,
        color: "#FE7235"
    }, title2: {
        fontFamily: "Lato",
        fontSize: 15,
        color: "#000000"
    },
    title3: {
        fontFamily: "Lato",
        fontSize: 15,
        color: "#1F2E99"
    },  
    pinicon: {
        width: 12.666,
        height: 16,
    }, 
      photoicon: {
        width: 19,
        height: 19,
    }, 
 
    container: {
        flex: 1
    },
});
