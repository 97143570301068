import * as React from 'react';
import { TextInput,Text,View,StyleSheet,ImageBackground ,Dimensions,Image,TouchableOpacity} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { OrangeButton } from './OrangeButton';
import { OrangeBorderButton } from './OrangeBorderButton';
import  RadioButton  from '../components/RadioButton'
import Moment from 'moment';
//import Datepicker from 'react-native-web-ui-components/Datepicker';
//import  DateTimePicker  from '../components/DateTimePicker'
import {Picker} from '@react-native-picker/picker';

import Select from 'react-native-web-ui-components/Select';
import validate from '../screens/validate_wrapper'
import { CarePlan,CarePlanWeekSelector } from '../components/CarePlan';
import { TextInputMask } from 'react-native-masked-text'

//export function OrangeButton(props) {
    export const InvitePatient = (props) => {
        const [formState, setFormState] = React.useState(2);
      //  const [data, setData] = React.useState(null);

        const [dateofbirth, setDateofbirth] = React.useState('');

        const [firstname, setFirstname] = React.useState('');
        const [lastname, setLastname] = React.useState('');
        const [email, setEmail] = React.useState(null);
        const [gsm, setGsm] = React.useState('');
        const [gender, setGender] = React.useState(0);
        const [operationtype, setOperationtype] = React.useState(0);
        const [height, setHeight] = React.useState('');
        const [weight, setWeight] = React.useState('');
        const [nhsnumber, setNhsnumber] = React.useState('');
        const [clinicalteamid, setClinicalteamid] = React.useState(0);
        const [clinicalteams, setClinicalteams] = React.useState();
        const [operationdate, setOperationdate] = React.useState("");

         

        const [caretakerfirstname, setCaretakerfirstname] = React.useState('');
        const [caretakerlastname, setCaretakerlasttname] = React.useState('');
        const [caretakergsm, setCaretakergsm] = React.useState('');
        const [caretakeremail, setCaretakeremail] = React.useState(null);
        const [errorText,setErrorText]=React.useState('');
        
        const [week, setWeek] = React.useState(1);
        function onPrev(){setWeek(Math.max(1,week-1))};
        function onNext(){setWeek(Math.min(60,week+1))};

        const inputs = document.querySelectorAll('input');


  React.useEffect(() => {
    async function fetchData() {
      let tokenparam = 'None';
      
      if (props.userStore.sessiontoken) { tokenparam = 'Token ' + props.userStore.sessiontoken }
      fetch(global.apiurl + '/clinicians/getcliniciansteams', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': tokenparam,
        }
       
      }).then((response) => response.json())
        .then(resData => {
          console.log(resData);
          if (resData.result) {
            setClinicalteams(resData.data);
          } else {
          }

        }).catch(err => {
          console.log(err);
       alert("Please check your connection.");

        });
    }
    fetchData();
  }, []);



inputs.forEach(input => {
  input.setAttribute('autocomplete', 'off')
  input.setAttribute('autocorrect', 'off')
  input.setAttribute('autocapitalize', 'off')
  input.setAttribute('spellcheck', false)
})


function setDate(text){

  console.log(text);
  //let pp=new Date(text);

  text.split("/")
  var pp = Moment(text);

  console.log(pp);

 setDateofbirth(new Date(pp));
}

function addCss(cssCode) {
  var styleElement = document.createElement("style");
    styleElement.type = "text/css";
    if (styleElement.styleSheet) {
      styleElement.styleSheet.cssText = cssCode;
    } else {
      styleElement.appendChild(document.createTextNode(cssCode));
    }
    document.getElementsByTagName("head")[0].appendChild(styleElement);
  }

//CSS(".react-datepicker-ignore-onclickoutside{color:red!important}")
//addCss(".react-datepicker__input-container input {margin-top: 4px!important;margin-right: 4px!important;margin-left: 8px!important;font-family:Lato!important;font-size:15px!important;color:#1f2e99!important;border:0px!important;background-color:transparent!important;background-size:18px 18px;background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAMAAAC7m5rvAAAAP1BMVEUAAAAeLpcfLpkfLpkcLJceLZofLpofLpkeLZsdLZcgLpseLpkfLZoeLZggKpUgMJcgLJcgMJ8eL5oeLpcfLZhiTFIdAAAAFXRSTlMAgP/fQJ/P749gf3C/nzAgQBCfoL9ktMvYAAAAcUlEQVR4Ae3VJQIDAQxE0dngMt//rI0pc00pz38XwJGCAuMEUyhw6Lcz0T2j4HrCKZjulBVQ0wsaML3AQS/5zCyzzNruYe1BVuFh1V9nmfV6aHg0G+mQ4FxmmU3joenP902rh+kXPaqWXjBjWOhp67ABX34VLb7jAfYAAAAASUVORK5CYII=)!important;}")


        function removeView(){
            props.removeView();
        }
      

        function isValidDate(dateObject){


          return Moment(dateObject, 'DD/MM/YYYY').isValid()
      }
        function validateForm(){
          setErrorText("");
          
          
          const firstnameError = validate('firstname', firstname);
          const lastnameError = validate('lastname', lastname);
          const genderError = validate('biggerthanzero', gender);
          const gsmError = validate('gsm2', gsm);
          const emailError = validate('emailoptional', email);

          const heightError = validate('height', height);
          const weightError = validate('weight', weight);
          const operationtypeError = validate('biggerthanzero', operationtype);
          const nhsnumberError = validate('nhsnumber', nhsnumber);
          const clinicalteamError = validate('clinicalteam', clinicalteamid);
          
        
          const caretakerfirstnameError = validate('genericname', caretakerfirstname);
          const caretakerlastnameError = validate('genericname', caretakerlastname);
          const caretakergsmError = validate('caretakergsm', caretakergsm);
          const caretakeremailError = validate('caretakeremail', caretakeremail);

         let  valid=false;


         let dateofbirthError="";

         if (!isValidDate(dateofbirth)) {dateofbirthError="Please enter a valid birthdate.";}
  
         let operationdateError="";

         if (!isValidDate(operationdate)) {operationdateError="Please enter a valid operation date.";}


         switch(formState){
          case 1:
          
            if ((!firstnameError&&!lastnameError&&!genderError&&!dateofbirthError&&!gsmError&&!emailError)) {
              setFormState(2);

            } else {
setErrorText("Please check fields: "+ (firstnameError?" first name"+" - ":"")+(lastnameError?"last name - ":"")+(genderError?" gender  - " :"")+(dateofbirthError?" date of birth - ":"")+(gsmError?" phone number - ":"")+(emailError?" email -":""));    

            }
            break;
            case 2:
          
            if ((!heightError&&!weightError&&!operationdateError&&!operationtypeError&&!nhsnumberError&&!clinicalteamError)) {
              setFormState(3);

            } else {
              setErrorText("Please check fields: "+ (heightError?" height - ":"")+(weightError?" weight -":"")+(operationtypeError?" operation type"+" - ":"") +(operationdateError?" operation date"+" - ":"")+(nhsnumberError?"NHS number - ":"")+(clinicalteamError?" clinical team  - " :""));    

            }
            break;
            case 3:
          
              if ((!caretakerfirstnameError&&!caretakerlastnameError&&!caretakergsmError&&!caretakeremailError)) {
                setFormState(4);
  
              } else {
                setErrorText("Please check fields: "+ (caretakerfirstnameError?" first name - ":"")+(caretakerlastnameError?" last  name -":"")+(caretakergsmError?" phone number"+" - ":"") +(caretakeremailError?"  email"+" - ":""));    

              }
              break;


         }
         

          
          
          
          }
          

          
          function invitePatient() {
            console.log("logging in");
        
              fetch(global.apiurl+'/clinicians/invitepatient', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                 
                  firstname: firstname,
                  lastname: lastname,
                  gsmcountrycode: global.countrycode,
                  gsm: gsm,
email:email,  
    gender:gender,  
    dateofbirth:dateofbirth,
    height:height,  
    weight:weight,  
    operationdate:operationdate,
    nhsnumber:nhsnumber,
    clinicalteamid:clinicalteamid,
    caretakerfirstname:caretakerfirstname,
    caretakerlastname:caretakerlastname,
    caretakeremail:caretakeremail,
    caretakergsmcountrycode:global.countrycode,
    caretakergsm:caretakergsm,
                 
                })
              })
                .then(res => {
                   
                  if (res.status === 422) {
        
                    throw new Error('Request failed.');
                  }
                  if (res.status !== 200 && res.status !== 201) {
                    console.log('Error!');
                    throw new Error('Could not authenticate you!');
                  }
                  return res.json();
                })
                .then(resData => {
                  console.log(resData);
                 if (resData.result){
                   console.log("DONE");
                  props.onFinish();
         //todo close ve listede clientı gorsun
        
        
        
                  } else {
          
                alert("Unable to invite, please try later");
                }
                 
                  
                })
                .catch(err => {
                  alert("Unable to invite, please try later");
        
                  console.log(err);
                
                });
            };
        const insets = useSafeAreaInsets();

        const dimensions = Dimensions.get('window');
const bottomdistance=dimensions.height/25+insets.bottom;


function getmenuitemclass(buttonid,activeid){
  if (buttonid==activeid) return styles.menuitemactive;
  if (buttonid<activeid) return styles.menuitemdone;
  return styles.menuitemwait;



}
    return   <View style={[{backgroundColor:'rgba(52, 52, 52, 0.8)',paddingTop:50,paddingBottom:50}]}> 
           

     <View style={{borderRadius:8,backgroundColor:"#ffffff",width:1130,height:726,alignSelf:"center"}}>

        <View style={{flexDirection:"row",width:"100%",borderBottomColor:"#E8E9ED",borderBottomWidth:1,height:70}}>
<Text style={styles.title1}>Invite a new patient</Text>
<Text style={{color:"#FF0000",flex:1,textAlign:"center",fontFamily:"LatoBold",fontSize:14,marginTop:28}}>{errorText}</Text>
<TouchableOpacity style={{position:"absolute",right:0}} onPress={() => removeView()}> 
<View style={{backgroundColor:"#FCF5EF",width:93,height:70,flexDirection:"row",borderLeftColor:"#E8E9ED",borderLeftWidth:1,borderTopRightRadius:8,alignItems:"center"}}>
  
<Image
source={require('../assets/images/closecrossicon.png')}
style={{width:10,height:10,marginRight:8,marginLeft:23,}}     />
<Text style={{fontFamily:"Lato",fontSize:13,color:"#1F2E99"}}>Close</Text>


</View></TouchableOpacity>

    </View>

    <View style={{flexDirection:"row"}}>
    <View style={{width:268,borderRightWidth:1,borderRightColor:"#E8E9ED",height:582,flexDirection:"row"}}>
    
    {(formState==1)&&<Image
source={require('../assets/images/invitepatientprogess1.png')}
style={{width:32,height:164,marginTop:33,marginLeft:24,}}     />}

    {(formState==2)&&<Image
source={require('../assets/images/invitepatientprogess2.png')}
style={{width:32,height:164,marginTop:33,marginLeft:24,}}     />}

    {(formState==3)&&<Image
source={require('../assets/images/invitepatientprogess3.png')}
style={{width:32,height:164,marginTop:33,marginLeft:24,}}     />}

    {(formState==4)&&<Image
source={require('../assets/images/invitepatientprogess4.png')}
style={{width:32,height:164,marginTop:33,marginLeft:24,}}     />}
<View style={{marginTop:11,marginLeft:15}}>
<Text style={getmenuitemclass(1,formState)}>Personal details</Text>
<Text style={getmenuitemclass(2,formState)}>Clinical details</Text>
<Text style={getmenuitemclass(3,formState)}>Next of kin details</Text>
<Text style={getmenuitemclass(4,formState)}>Patient care plan</Text>
</View>

    </View>
    <View style={{width:863,paddingLeft:20}}>
   
    {(formState==1)&&<View>
    <Text style={styles.title2}>Insert personal details of patient</Text>
    



    <View style={{flexDirection:"row",marginTop:32}}>
      <View style={{width:250}}>
      <Text style={styles.title3}>First name</Text>
      <View style={[styles.shadowinput, { width: 250 }]} >
                <TextInput
                  style={[styles.postopinput, { textAlign: "left", width: 250 }]} keyboardType="name-phone-pad"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={text => setFirstname(text)}
                  value={firstname}
                  returnKeyType='done' />
              </View>
      </View>
      <View style={{width:250,marginLeft:18}}>
      <Text style={styles.title3}>Last name</Text>
      <View style={[styles.shadowinput, { width: 250 }]} >

      <TextInput
                  style={[styles.postopinput, { textAlign: "left", width: 250 }]} keyboardType="name-phone-pad"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={text => setLastname(text)}
                  value={lastname}
                  returnKeyType='done' />

</View>
</View>


            
        
      </View> 

    
      <Text style={[styles.title3,{marginTop:32}]}>Gender</Text>

       <View style={{flexDirection:"row",marginTop:8}}>
            <RadioButton selected={gender==1} onSelected={()=>{setGender(1);}} title="Male" />
            <RadioButton selected={gender==2} onSelected={()=>{setGender(2);}} title="Female" />

            <RadioButton selected={gender==3} onSelected={()=>{setGender(3);}} title="Other" />

            </View>
            <Text style={[styles.title3,{marginTop:32}]}>Date of birth</Text>

            {false&&<View style={{zIndex:110,width:200}}>
            <View style={[styles.shadowinput, { width: 200 }]} >

      



</View>

</View>}

<View style={{zIndex:110,width:200}}>
            <View style={[styles.shadowinput, { width: 200 }]} >
           
    
            <TextInputMask    type={'datetime'}
  options={{
    format: 'DD/MM/YYYY'
  }}
  value={dateofbirth}
placeholder={"DD/MM/YYYY"}
  style={{ color: "#1F2E99", fontFamily: "Lato", marginLeft:20, fontSize: 15,marginRight: 10,marginTop:1 }}
  onChangeText={setDateofbirth} />

        
</View>
</View>

      <View style={{width:250}}>
      <Text style={[styles.title3,{marginTop:32}]}>Phone number</Text>
      <View style={[styles.shadowinput]} >
                <Image
                  source={require('../assets/images/countryflag.png')}
                  style={styles.countryflag} />
                  <View style={{width:1,height:48,backgroundColor:"#DFE1E6"}}></View>
                <Text style={{ color: "#A8B1C1", fontFamily: "Lato", fontSize: 15,marginTop:16, marginRight: 6,marginLeft:8 }}>+44</Text>
                <TextInput
                 keyboardType={"number-pad"}
                onChangeText={text => setGsm(text)}
                value={gsm}
                maxLength={15}
                style={{ color: "#1F2E99", fontFamily: "Lato", fontSize: 15,marginRight: 10,marginTop:1 }} />

              </View>
      </View>
      <View style={{width:250}}>
      <Text style={[styles.title3,{marginTop:32}]}>Email address (optional)</Text>

      <View style={[styles.shadowinput, { width: 250 }]} >
                <TextInput
                  style={[styles.postopinput, { textAlign: "left", width: 250 }]} keyboardType="email-address"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={text => setEmail(text ? text : null)}
                  value={email}
                  returnKeyType='done' />
              </View>

      </View>
        







    </View>}

    {(formState==2)&&<View>
    <Text style={styles.title2}>Add patient’s clinical details</Text>



<View style={{flexDirection:"row"}}>



<View style={{width:85}}>

    <Text style={[styles.title3,{marginTop:24}]}>Height</Text>

<View style={{ flexDirection: 'row',}}>
<View  style={[styles.shadowinput,{width:60}]} >
 <TextInput
      style={[styles.postopinput,{textAlign:"center",marginLeft:0,width:60,"text-align:":"center"}]}  keyboardType="number-pad"
      maxLength={3}
      onChangeText={text => setHeight((text))}
      value={height}
      returnKeyType='done' />
    </View>   
    <Text style={[styles.title3,{marginTop:25,marginLeft:8}]}>cm</Text>
    </View>
</View>
<View style={{width:85,marginLeft:42}}>
 
    <Text style={[styles.title3,{marginTop:29}]}>Weight</Text>

<View style={{ flexDirection: 'row',}}>
<View  style={[styles.shadowinput,{width:60}]} >
 <TextInput
      style={[styles.postopinput,{textAlign:"center",marginLeft:0,width:60}]} keyboardType="number-pad"
      maxLength={3}
      onChangeText={text => setWeight(text)}
      value={weight}
      returnKeyType='done' />
    </View>   
    <Text style={[styles.title3,{marginTop:25,marginLeft:8}]}>kg</Text>
    </View>

</View>
</View>
    <Text style={[styles.title3,{marginTop:32}]}>Operation type</Text>

    <View style={{flexDirection:"row",marginTop:24}}>
            <RadioButton selected={operationtype==1} onSelected={()=>{setOperationtype(1);}} title="Knee" />
            <RadioButton selected={operationtype==2} onSelected={()=>{setOperationtype(2);}} title="Hip" />


            </View>


            

            <Text style={[styles.title3,{marginTop:32}]}>Operation Date</Text>


          

<View style={{zIndex:110,width:200}}>
            <View style={[styles.shadowinput, { width: 200 }]} >
           
            <TextInputMask    type={'datetime'}
  options={{
    format: 'DD/MM/YYYY'
  }}
  value={operationdate}
placeholder={"DD/MM/YYYY"}
  style={{ color: "#1F2E99", fontFamily: "Lato", marginLeft:20, fontSize: 15,marginRight: 10,marginTop:1 }}
  onChangeText={setOperationdate} />



</View></View>

{false&&<View style={[styles.shadowinput, { width: 200 }]} >

<Text
style={[styles.postopinput, { width: 150, marginTop: 15 }]}
>{Moment(operationdate).format("DD / MM / YY")} </Text>
<TouchableOpacity  style={{ top: 13, left: 15 }}>
<Image
source={require('../assets/images/calendaricon.png')}
style={styles.calendaricon} />
</TouchableOpacity>

</View>} 

    <Text style={[styles.title3,{marginTop:32}]}>NHS number</Text>

<View style={{ flexDirection: 'row',}}>
<View  style={[styles.shadowinput,{width:163}]} >
 <TextInput
      style={styles.postopinput}  keyboardType="number-pad"
      maxLength={10}
      onChangeText={text => setNhsnumber((text))}
      value={nhsnumber}
      returnKeyType='done' />
    </View>   
    </View>


    <Text style={[styles.title3,{marginTop:29}]}>Clinical Team</Text>

    <View  style={[styles.shadowinput,{zIndex:100,width:163,alignItems:"center"}]} >


    <Picker
   //style={{"-webkit-appearance":"none", "text-align":"center","text-align-last":"center","-moz-text-align-last":"center",width:76,height:24,borderRadius:20,fontFamily:"LatoBold",fontSize:13,color:"#ffffff",backgroundColor:"#6DD803",alignItems:"center",borderWidth:0}}
   style={[styles.select    ] }
  selectedValue={clinicalteamid}
  onValueChange={(itemValue, itemIndex) =>{
 setClinicalteamid(itemValue);
  }}  >

{clinicalteamid==0&&<Picker.Item label="" value={0} />}

  {clinicalteams&&clinicalteams.map(clinicalteam => (
  <Picker.Item label={clinicalteam.name} value={clinicalteam.id} />))
  
  }

</Picker>


  {false&&<Select style={{"border-width":"0",width:240,marginTop:5,zIndex:100}} 
  spellcheck="false"
  selectTextStyle={{"color":"#1F2E99","font-family":"Lato","font-size":"15px"}}
  selectStyle={{"border-width":"0px",}}
  values={[1,2,3,1,2,3,1,2,3]}
  labels={["NVD team","ACD team","MCY team","NVD team","ACD team","MCY team","NVD team","ACD team","MCY team"]}
  onChange={x =>setClinicalteamid(x)}
  value={clinicalteamid}
  ></Select>}
            </View>
   












    </View>}

    {(formState==3)&&<View>
    <Text style={styles.title2}>Insert details about the caretaker or family member of the patient</Text>





    <Text style={[styles.title3, { marginTop: 32 }]}>First name</Text>
              <View style={[styles.shadowinput, { width: 300 }]} >
                <TextInput
                  style={[styles.postopinput, { textAlign: "left", width:250 }]} keyboardType="name-phone-pad"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={text => setCaretakerfirstname(text)}
                  value={caretakerfirstname}
                  returnKeyType='done' />
              </View>
              <Text style={[styles.title3, { marginTop: 29 }]}>Last name</Text>
              <View style={[styles.shadowinput, { width: 300 }]} >
                <TextInput
                  style={[styles.postopinput, { textAlign: "left", width: 300 }]} keyboardType="name-phone-pad"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={text => setCaretakerlasttname(text)}
                  value={caretakerlastname}
                  returnKeyType='done' />
              </View>


            

              <Text style={[styles.title3, { marginTop: 32 }]}>Phone number</Text>
              <View style={[styles.shadowinput, { width: 300 }]} >
                <Image
                  source={require('../assets/images/countryflag.png')}
                  style={styles.countryflag} />
                  <View style={{width:1,height:48,backgroundColor:"#DFE1E6"}}></View>
                <Text style={{ color: "#A8B1C1", fontFamily: "Lato", fontSize: 15,marginTop:16, marginRight: 6,marginLeft:8 }}>+44</Text>
                <TextInput
                
                onChangeText={text => setCaretakergsm(text)}
                value={caretakergsm}
                maxLength={15}

                style={{ color: "#1F2E99", fontFamily: "Lato", fontSize: 15,marginRight: 10,marginTop: 1 }} />
      
              </View>


              <Text style={[styles.title3, { marginTop: 32 }]}>Email address (optional)</Text>
              <View style={[styles.shadowinput, { width: 300 }]} >
                <TextInput
                  style={[styles.postopinput, {textAlign: "left", width: dimensions.width - 85 }]} keyboardType="email-address"
                  maxLength={50}
                  numberOfLines={1}
                  onChangeText={text => setCaretakeremail(text ? text : null)}
                  value={caretakeremail}
                  returnKeyType='done' />
              </View>


    </View>}
    

    {(formState==4)&&<View>
    <Text style={[styles.title2,{marginTop:24}]}>Review care plan of the patient</Text>
    <CarePlanWeekSelector  style={{top:23}} week={week} onPrev={onPrev} onNext={onNext}/>
            <CarePlan  style={{marginLeft:-21,marginTop:25,overflow:"hidden",width:862}}  week={week}></CarePlan>

            
    </View>}


    </View>


    </View>
    <View style={{flexDirection:"row",borderWidth:1,borderColor:"#E8E9ED",height:74,justifyContent:"space-between",zIndex:-100,borderBottomLeftRadius:8,borderBottomRightRadius:8}}>
    <View style={{width:268,borderRightWidth:1,borderColor:"#E8E9ED"}}>
   
   </View>
   <View style={{width:300,paddingTop:16,paddingRight:22,alignItems:"flex-end"}}>
   {(formState==1)&&<OrangeBorderButton   onPress={() => validateForm()} title="Next, insert clinical details"  style={{width:250}} />}
  {(formState==2)&&<OrangeBorderButton   onPress={() => validateForm()} title="Next, insert caretaker details" style={{width:250}}/>}
  {(formState==3)&&<OrangeBorderButton   onPress={() => validateForm()} title="Next, review care plan" style={{width:250}} />}
  {(formState==4)&&<OrangeButton   onPress={() => {invitePatient()}} title="Send SMS invitation" style={{width:250}} />}
  </View>
   
   </View>
    </View> </View>
    ;
 // return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}

const styles = StyleSheet.create({


 select:{     marginLeft:20,
      //  "text-align":"center","text-align-last":"center",
        "-moz-appearance": "none",
        "-webkit-appearance": "none",
        "appearance": "none",
        "backgroundImage":"url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAVCAYAAAAuJkyQAAAAmUlEQVR42r3OwQmAQAxE0S3JTrUDvairl6ANBG1MySFeFrz8kIGBZRnIK55ZtLOWnPzf3c67r8f1WO2dhannPTZ3TeefXhtmYrxm+UAARTAtyLLKNTEUxyyig28gimNW0akZcxTHAFQCBqAwJgEFMACFMRkojuEojolHcUw8CmLiUQCTgAIYhAKYJBTH8FTRAWLis4t21hKQF5HBtPSQyO2PAAAAAElFTkSuQmCC)",
        "backgroundPosition":"87% 50%",
        "backgroundSize":"12px 7px",
        "background-repeat": "no-repeat",borderWidth:0,
        width:163,height:24,borderRadius:20,fontFamily:"Lato",fontSize:15,color:"#1F2E99",
 textAlign:"center"
    },


  menuitemwait:{
    fontFamily:"Lato",fontSize:15,color:"#959DAD",marginTop:27,height:18
  },
  menuitemdone:{
    fontFamily:"Lato",fontSize:15,color:"#1F2E99",marginTop:27,height:18
  },
  menuitemactive:{
    fontFamily:"LatoBold",fontSize:15,color:"#1F2E99",marginTop:27,height:18

  },

    postopinput: {
        flex:1,outlineWidth:0,
        color:"#1F2E99",fontFamily:"Lato",fontSize:15,
        marginRight:10,
        marginLeft:20,
      },
    countryflag: {
        width: 26,
        height: 18,
        marginHorizontal: 16,
        marginVertical: 19
      },  
    shadowinput:{
            
        marginTop:8,
        height:48,
       flexDirection: 'row',borderRadius:15,
        shadowColor: "#818181",
        backgroundColor:"#FFFFFF",
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        
        elevation: 5,
    },
    title1: {
        fontSize: 18,
        fontFamily: 'LatoBold',
    color:"#1F2E99",
    marginTop:24,
    marginLeft:24
    
      },

      title2: {
        fontSize: 15,
        fontFamily: 'LatoBold',
    color:"#1F2E99",
    marginTop:32,
    
      }, 
      
      calendaricon: {
        width: 18,
        height: 18,
      },

      title3: {
        fontSize: 13,
        fontFamily: 'Lato',
    color:"#798599",
    
      }
  });
  