import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { Dimensions } from 'react-native';
import {inject,observer} from "mobx-react";
 





import PatientOverviewScreen from '../screens/PatientOverviewScreen';
import TermsConditionsScreen from '../screens/TermsConditionsScreen';
import PatientWoundsScreen from '../screens/PatientWoundsScreen';
import UpdateProfileScreen from '../screens/UpdateProfileScreen';
import ProfileScreen from '../screens/ProfileScreen';
import TeamScreen from '../screens/TeamScreen';


import PatientPROMSScreen from '../screens/PatientPROMSScreen';
import PatientPREMSScreen from '../screens/PatientPREMSScreen';
import PatientGeneralquestionsScreen from '../screens/PatientGeneralquestionsScreen';
import PatientCarePlanScreen from '../screens/PatientCarePlanScreen';
import PatientMessagesScreen from '../screens/PatientMessagesScreen';
import PatientDiaryScreen from '../screens/PatientDiaryScreen';
import TempScreen from '../screens/TempScreen';
import TempScreen3 from '../screens/TempScreen3';
import LoginScreen from '../screens/user/LoginScreen';
import ResetPasswordScreen from '../screens/user/ResetPasswordScreen';
import DashboardScreen from '../screens/DashboardScreen';
import PatientListScreen from '../screens/PatientListScreen';
import RegisterScreen from '../screens/user/RegisterScreen';
import RegisterSetPasswordScreen from '../screens/user/RegisterSetPasswordScreen';
import RegisterUpdateDetailsScreen from '../screens/user/RegisterUpdateDetailsScreen';
import LinkingConfiguration from './LinkingConfiguration';

import { LoggedinParamList, RootStackParamList, WelcomeStackParamList } from '../types';


import { MobXProviderContext } from 'mobx-react'
function useStores() {
  return React.useContext(MobXProviderContext)
}
const MyTheme = {
  ...DefaultTheme,
  
  colors: {
    ...DefaultTheme.colors,
    notification: '#F65031',
    
  },
}

//colorScheme === 'dark' ? DarkTheme : DefaultTheme}
// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started

//const Navigation=inject("userStore")(({userStore,colorScheme})=> {
const Navigation=({userStore})=> { 
    console.log("xxxx");
    /**
     * 
     *         <Text style={{position:"absolute",top:100,zIndex:100}}>xx{userStore?.firstname}</Text>

     * 
     */



    const onChange = ({ window, screen }) => {
      console.log("goto redraw");
     userStore.updatescreen();
    };

useEffect(() => {
  Dimensions.addEventListener('change', onChange);
  return () => {
    Dimensions.removeEventListener('change', onChange);
  }
}, [])
//console.log(userStore.sessiontoken);

    return (
    <NavigationContainer     linking={LinkingConfiguration}
      theme={MyTheme}>
    {(!userStore.userloggedin)&&<WelcomeFlow  />}
      {userStore.userloggedin&&<LoggedinFlow />}
    </NavigationContainer>
  );
};

export default inject(({userStore})=>({userStore}))(observer(Navigation));

const StackW = createStackNavigator<WelcomeStackParamList>();

function WelcomeFlow() {
  return (
    <StackW.Navigator screenOptions={{ headerShown: false }}  initialRouteName="RegisterCode">
    
    <StackW.Screen name="RegisterCode" component={RegisterScreen} options={{gestureEnabled:false,}} />
    <StackW.Screen name="RegisterSetPasswordScreen" component={RegisterSetPasswordScreen} />
    <StackW.Screen name="RegisterDone" component={TempScreen}  options={{gestureEnabled:false,}} />
    <StackW.Screen name="LoginScreen"  component={LoginScreen}   options={{gestureEnabled:false,}} />
    <StackW.Screen name="ResetPasswordScreen" component={ResetPasswordScreen}  options={{gestureEnabled:false,}}  />
    <StackW.Screen name="ResetPasswordDoneScreen" component={TempScreen}  options={{gestureEnabled:false,}} />
    <StackW.Screen name="RegisterUpdateDetailsScreen" component={RegisterUpdateDetailsScreen}  options={{gestureEnabled:false,}}/>

    </StackW.Navigator>
  );
}

const StackStackLoggedin = createStackNavigator<LoggedinParamList>();

function LoggedinFlow() {
  return (
    <StackStackLoggedin.Navigator screenOptions={{ headerShown: false }}>
    
    <StackStackLoggedin.Screen name="DashboardScreen" component={DashboardScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="PatientListScreen" component={PatientListScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="PatientOverviewScreen" component={PatientOverviewScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="PatientWoundsScreen" component={PatientWoundsScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="PatientPROMSScreen" component={PatientPROMSScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="PatientPREMSScreen" component={PatientPREMSScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="PatientGeneralquestionsScreen" component={PatientGeneralquestionsScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="PatientCarePlanScreen" component={PatientCarePlanScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="PatientMessagesScreen" component={PatientMessagesScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="PatientDiaryScreen" component={PatientDiaryScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="UpdateProfileScreen" component={UpdateProfileScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="ProfileScreen" component={ProfileScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="TeamScreen" component={TeamScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="TermsConditionsScreen" component={TermsConditionsScreen}  options={{gestureEnabled:false,}}/>
      <StackStackLoggedin.Screen name="TempScreen3" component={TempScreen3}  options={{gestureEnabled:false,}}/>
  
    </StackStackLoggedin.Navigator>
  );
}



